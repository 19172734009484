import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import CoreConstants from "../constants/CoreConstants";
import {getMessageTemplates} from "../inboxAction";
import Select from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import QucikReplyCard from "../QuickReplyCard";
import GlobalModal from "../globals/Modal/GlobalModal";
import GlobalPrivacyTab from "../../../SendComponents/globalPrivacyTab";
import { PRIVACY_LEVEL, TEMPLATE_TYPE } from "../../../../helpers/CoreConstant";
import useDebounce from "../../common/useDebounce";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

const App = props => {
    const classes = useStyles();

    const [show, setShow] = useState(props.openSavedReplyModal)

    const [activeTab, setActiveTab] = useState(PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL);
    const [search, setSearch] = useState("");

	const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        let type = props.templateType === TEMPLATE_TYPE.SMS ? [TEMPLATE_TYPE.SMS, TEMPLATE_TYPE.MMS] : props.templateType

        props.getMessageTemplates({ search_key: debouncedSearch, type: type, privacy_level: activeTab });

    }, [debouncedSearch, props.templateType, activeTab]);


    const selectThisMessageTemplate = (e, data) => {
        if(e !== undefined && e !== null){
            e.preventDefault();
            e.stopPropagation();
        }
        props.useMessageTemplate(data);
    }

    const renderMessageTemplates = () => {
        let messageTemplateOptions = [];
        if (props.messageTemplates != null) {
            if(props.messageTemplates.length === 0){
                return (
                    <div className="m-inbox-no-template">No template found</div>
                )
            }
            props.messageTemplates?.map((data, index) => {
                messageTemplateOptions.push(
                    <QucikReplyCard
                        data={data}
                        key={index}
                        onSelect={(value) => selectThisMessageTemplate(null, value)}
                    />
                )
            });
        }
        else{
            return (
                <div className="m-inbox-no-template">No template found</div>
            ) 
        }
        return messageTemplateOptions;
    }

    const getMessageTemplates = (event) => {
        // props.getMessageTemplates({ search_key: '', type: event.target.value });
        let type = event.target.value === TEMPLATE_TYPE.SMS ? [TEMPLATE_TYPE.SMS, TEMPLATE_TYPE.MMS] : event.target.value
        props.getMessageTemplates({ search_key: '', type: type, privacy_level: activeTab });
    }

    const handleActiveTab = (_event, newValue) => {
        setActiveTab(newValue);
    };
    return (
        <React.Fragment>
            <GlobalModal
                open={show}
                onClose={props.setOpenSavedReplyModal}
                hideFooter={true}
                title="Manage Quick Replies"
            >
            <div id="massaging-service-modal">
                <div className="manage_modal_input_fields d-flex justify-content-start align-items-center">
                    <div className="search_input">
                        <input type="text" placeholder="Type and Search" className="search_form mt-4" onChange={(e) => setSearch(e.target.value)} />
                        <span className="search_icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                <path
                                    d="M7.07036 14.6774C8.722 15.8878 10.7698 16.4299 12.8039 16.1953C14.8381 15.9607 16.7087 14.9667 18.0415 13.4121C19.3742 11.8575 20.0709 9.85709 19.992 7.81095C19.9132 5.7648 19.0647 3.82388 17.6162 2.37649C16.1678 0.929091 14.2263 0.0819647 12.1801 0.00458746C10.1339 -0.0727898 8.13391 0.625288 6.58031 1.95916C5.0267 3.29303 4.03403 5.16433 3.80088 7.19867C3.56774 9.23302 4.11131 11.2804 5.32286 12.9312V12.9299C5.27286 12.9674 5.22536 13.0074 5.17911 13.0524L0.366613 17.8649C0.132063 18.0993 0.000226974 18.4172 0.000110626 18.7488C-7.62939e-06 19.0804 0.131603 19.3985 0.365988 19.633C0.600372 19.8676 0.918333 19.9994 1.24992 19.9995C1.58151 19.9996 1.89956 19.868 2.13411 19.6336L6.94661 14.8211C6.99185 14.7765 7.03364 14.7284 7.07161 14.6774H7.07036ZM11.8754 14.9999C10.9725 14.9999 10.0785 14.8221 9.24441 14.4766C8.4103 14.1311 7.65241 13.6247 7.014 12.9863C6.3756 12.3479 5.86919 11.59 5.52369 10.7558C5.17819 9.92174 5.00036 9.02774 5.00036 8.1249C5.00036 7.22206 5.17819 6.32807 5.52369 5.49395C5.86919 4.65984 6.3756 3.90194 7.014 3.26354C7.65241 2.62514 8.4103 2.11873 9.24441 1.77323C10.0785 1.42773 10.9725 1.2499 11.8754 1.2499C13.6987 1.2499 15.4474 1.97423 16.7367 3.26354C18.026 4.55285 18.7504 6.30154 18.7504 8.1249C18.7504 9.94826 18.026 11.6969 16.7367 12.9863C15.4474 14.2756 13.6987 14.9999 11.8754 14.9999Z"
                                    fill="#133159" fillOpacity="0.35"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)"/>
                                </clipPath>
                                </defs>
                            </svg>
                        </span>
                    </div>
                    {typeof props.templateType === 'undefined' &&
                        <div className="input-field manage_modal_drp">
                            <FormControl>
                                <Select
                                    labelId="virtual-number-select-label"
                                    id="virtual-number-select"
                                    value={props.templateType}
                                    onChange={getMessageTemplates}
                                    className={"virtual-number-select"}
                                >
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.EMAIL}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.EMAIL}>Email
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.SMS}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.SMS}>SMS
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}>Voice
                                        Mail
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}>Video
                                        Email
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.MMS}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.MMS}>MMS
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.POST_CARD}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.POST_CARD}>Post
                                        Card
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.GREETING_CARD}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.GREETING_CARD}>Greeting
                                        Card
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.GIFT_CARD}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.GIFT_CARD}>Gift
                                        Card
                                    </option>
                                    <option value={CoreConstants.MESSAGE_TEMPLATE.LETTER}
                                            selected={props.templateType === CoreConstants.MESSAGE_TEMPLATE.LETTER}>LETTER
                                    </option>
                                </Select>
                            </FormControl>
                            <span className="drp_arrow_icon">
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 1.5L7 6.5L13 1.5" stroke="#renderMessageTemplateType133159"
                                        strokeOpacity="0.65" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                </svg>
                            </span>
                        </div>
                    }
                </div>

                <GlobalPrivacyTab activeTab={activeTab} handleTabChange={handleActiveTab} />

                <div className="video_thumbnails modal_thumbnail awesome__scroll_bar">
                    <div className="row video_thumbnails_scroll">
                        { renderMessageTemplates() }
                    </div>
                </div>
            </div>
            </GlobalModal>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        messageTemplates: state.inboxReducer.messageTemplates,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMessageTemplates: (params) => dispatch(getMessageTemplates(params)),
    };
}

const SavedReplyWithGlobalPrivacy = connect(mapStateToProps, mapDispatchToProps)(App);

export default SavedReplyWithGlobalPrivacy;
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography
} from "@material-ui/core";
import { DeleteForever } from "@material-ui/icons";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { markStepComplete } from "../../../api/onboardApi";
import { PHONE_TYPES } from "../../../constants/CoreConstants";
import useQueryParam from "../../../hooks/useQueryParam";
import { BasicMaskedInput } from "../../GlobalAddDealModalV3/GlobalAddDealModalStyles";
import GlobalModalV2 from "../../GlobalModal/Modal/GlobalModalV2";
import BasicInput from "../../custom/BasicInput";
import BasicSelect from "../../custom/BasicSelect";
import { CustomFieldType, US_STATE_LIST } from "../CoreConstants";
import { IconList } from "../IconList";
import Upgrade from "../Upgrade";
import Utils, { getState } from "../Utils";
import {
  getUserCustomFieldList,
  getUserSourceList,
  getUserTagList,
  setUserCustomFieldList,
  setUserSourceList,
  setUserTagList,
  storeUserCustomFieldsListNew,
} from "../addContactAction";
import { getContactLimit, getZipCodeByPlace, submitSingleContactV2, } from "../contactApi";
import "./../addContact.css";
import { BasicInputSelect, useStyles, } from "./AddContactModalV2Styles";
import GlobalAddContactModalSkeleton from "./GlobalAddContactModalSkeleton";
import LeadType from "./LeadType";
const GOOGLE_API_KEY = process.env.REACT_APP_MAP_KEY;

const AddContactModal = (props) => {
  const history = useHistory();
  const [contactForm, setContactForm] = useState({
    selected_source: "",
    lead_type: "",
    first_name: "",
    last_name: "",
    numbers: [{ number: "", number_state: "mobile" }],
    emails: [{ email: "" }],
    company_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    url: "",
    deal_value: 0,
    birth_day: "",
    anniversary_date: "",
  });

  const [firstLoading, setFirstLoading] = useState(true);
  const [leadSources, setLeadSources] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const [submittingContact, setSubmittingContact] = useState(false);
  const [birthDate, setBirthDate] = useState(null);
  const [anniversaryDate, setAnniversaryDate] = useState(null);
  const [value, setValue] = useState(null);
  const [contactLimit, setContactLimit] = useState(0);
  const [currentContact, setCurrentContact] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddress, setShowAddress] = useState(false);

  const [additionalDates, setAdditionalDates] = useState([]);
  const [additionalDatesErrors, setAdditionalDatesErrors] = useState([]);
  const openTab = useQueryParam("open-tab");
  const from = useQueryParam("from");

  useEffect(() => {
    if (firstLoading) {
      setIsLoading(true);
      setFirstLoading(false);

      getContactLimit().then((res) => {
        setIsLoading(false);
        let data = res.data.data;
        if (data.contactLimit > data.currentContact) {
          fetchSourceInfos().then();
          fetchUserCustomFields().then();
        }
        if (data.contactLimit) {
          setContactLimit(data.contactLimit);
          setCurrentContact(data.currentContact);
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let tempCustomData = [...customFields];
    setCustomFields(tempCustomData);
  }, [customFieldsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSourceInfos = async () => {
    if (props.storedSourceList !== null) {
      setLeadSources(props.storedSourceList);
    } else if (props.data?.sourceList !== undefined && props.data?.sourceList != null) {
      setLeadSources(props.data.sourceList);
    } else {
      await props.fetchUserSourceList({}, (callback) => {
        setLeadSources(callback);
      });
    }
  };

  const fetchUserCustomFields = async () => {
    if (props.storedCustomField !== null) {
      setCustomFields(props.storedCustomField);
    } else {
      window
        .globalCustomFieldWithPersonalized({
          get_custom_data: true,
          get_personalized_data: false,
          field_name: ["*"],
          for_drop_down: false,
          drop_down_field: {
            value_field: "id",
            title_field: "title",
          },
        })
        .then((res) => {
          let customFieldDatas = res.data.custom_field;
          let customFieldValues = [];
          customFieldDatas.forEach((row, index) => {
            customFieldValues.push(null);
          });
          setCustomFields(customFieldDatas);
          setCustomFieldsData(customFieldValues);
          props.storeUserCustomFieldsNew(customFieldDatas);
        });
    }
  };

  const handleSelectChange = (value) => {
    setContactForm({ ...contactForm, selected_source: value });
  };

  const handleInputField = (field, value) => {
    let tempContactForm = { ...contactForm };
    tempContactForm[field] = value;
    setContactForm(tempContactForm);
  };

  const handleAddress = async (event) => {
    let placeId =
      event.place_id !== undefined && event.place_id != null
        ? event.place_id
        : null;
    let zip = "";
    let road = "";
    let streetNumber = "";
    let state = "";
    let city = "";
    let country = "";
    let county = "";
    if (placeId != null) {
      try {
        await getZipCodeByPlace({
          placeId: placeId,
          googleAPIKey: GOOGLE_API_KEY,
        }).then((res) => {
          try {
            if (
              res.data.data.result.formatted_address !== undefined &&
              res.data.data.result.formatted_address != null
            ) {
              let data_array =
                res.data.data.result.formatted_address.split(",");
              if (data_array.length === 4) {
                let state_zip = data_array[2].trim().split(" ");
                setContactForm({
                  ...contactForm,
                  address: data_array[0].trim(),
                  city: data_array[1].trim(),
                  state: state_zip[0],
                  country: data_array[3],
                  zip: state_zip[1],
                });
              } else {
                zip = res?.data?.zip;
                if (
                  res.data.data.result.address_components &&
                  Array.isArray(res.data.data.result.address_components)
                ) {
                  res.data.data.result.address_components.map((item, index) => {
                    if (item.types[0] === "street_number") {
                      streetNumber = item.long_name;
                    } else if (item.types[0] === "route") {
                      road = item.long_name;
                    } else if (
                      item.types[0] === "administrative_area_level_1"
                    ) {
                      state = item.short_name;
                    } else if (
                      item.types[0] === "administrative_area_level_2"
                    ) {
                      city = item.long_name;
                    } else if (item.types[0] === "country") {
                      country = item.long_name;
                    } else if (item.types[0] === "postal_code") {
                      zip = item.long_name;
                    } else if (
                      item.types[0] === "administrative_area_level_3"
                    ) {
                      county = item.long_name;
                    }
                    setContactForm({
                      ...contactForm,
                      address: streetNumber + " " + road,
                      city: county !== "" ? county : city,
                      state: state,
                      country: country,
                      zip: zip,
                    });
                  });
                } else {
                  updateContactInfo(event, zip);
                }
              }
            } else {
              zip = res?.data?.zip;
              if (
                res.data.data.result.address_components &&
                Array.isArray(res.data.data.result.address_components)
              ) {
                res.data.data.result.address_components.map((item, index) => {
                  if (item.types[0] === "street_number") {
                    streetNumber = item.long_name;
                  } else if (item.types[0] === "route") {
                    road = item.long_name;
                  } else if (item.types[0] === "administrative_area_level_1") {
                    state = item.short_name;
                  } else if (item.types[0] === "administrative_area_level_2") {
                    city = item.long_name;
                  } else if (item.types[0] === "country") {
                    country = item.long_name;
                  } else if (item.types[0] === "postal_code") {
                    zip = item.long_name;
                  } else if (item.types[0] === "administrative_area_level_3") {
                    county = item.long_name;
                  }
                  setContactForm({
                    ...contactForm,
                    address: streetNumber + " " + road,
                    city: county !== "" ? county : city,
                    state: state,
                    country: country,
                    zip: zip,
                  });
                });
              } else {
                updateContactInfo(event, zip);
              }
            }
          } catch (error) {
            zip = res?.data?.zip;
            if (
              res.data.data.result.address_components &&
              Array.isArray(res.data.data.result.address_components)
            ) {
              res.data.data.result.address_components.map((item, index) => {
                if (item.types[0] === "street_number") {
                  streetNumber = item.long_name;
                } else if (item.types[0] === "route") {
                  road = item.long_name;
                } else if (item.types[0] === "administrative_area_level_1") {
                  state = item.short_name;
                } else if (item.types[0] === "administrative_area_level_2") {
                  city = item.long_name;
                } else if (item.types[0] === "country") {
                  country = item.long_name;
                } else if (item.types[0] === "postal_code") {
                  zip = item.long_name;
                } else if (item.types[0] === "administrative_area_level_3") {
                  county = item.long_name;
                }
                setContactForm({
                  ...contactForm,
                  address: streetNumber + " " + road,
                  city: county !== "" ? county : city,
                  state: state,
                  country: country,
                  zip: zip,
                });
              });
            } else {
              updateContactInfo(event, zip);
            }
          }
        });
      } catch (e) {
        updateContactInfo(event, "");
        console.log(e);
      }
    }
  };

  const updateContactInfo = (event, zip) => {
    let fullAddressArr = event.description.split(",");
    let address =
      fullAddressArr[0] !== undefined ? fullAddressArr[0].trim() : "";
    let city = fullAddressArr[1] !== undefined ? fullAddressArr[1].trim() : "";
    let fullState =
      fullAddressArr[2] !== undefined ? fullAddressArr[2].trim() : "";
    let country =
      fullAddressArr[3] !== undefined ? fullAddressArr[3].trim() : "";

    let stateArr = fullState.split(" ");
    let state = stateArr[0] !== undefined ? stateArr[0].trim() : "";

    setContactForm({
      ...contactForm,
      address: address,
      city: city,
      state: state,
      country: country,
      zip: zip,
    });
  };

  const handleCustomFieldChange = (
    value,
    index_field,
    id,
    maxLength = null
  ) => {
    if ((maxLength != null) & (value.length > maxLength)) {
      return false;
    }

    let previousData = [...customFieldsData];
    if (value === "") {
      previousData[index_field] = null;
    } else {
      previousData[index_field] = {
        user_custom_field_id: id,
        value: value,
      };
    }
    setCustomFieldsData(previousData);
  };

  const isDateInvalid = (birth, anniversary) => {
    let birthDate = moment(birth).isValid();
    let anniversaryDate = moment(anniversary).isValid();

    if ((birth && anniversary) || birth || anniversary) {
      if (
        (birthDate && anniversaryDate) ||
        (birthDate && anniversary == null) ||
        (anniversaryDate && birth == null)
      ) {
        return false;
      } else {
        if (
          !birthDate &&
          anniversary != null &&
          !anniversaryDate &&
          birth != null
        ) {
          return "Both Birth & Anniversary Date are Invalid!";
        } else if (!birthDate && !birthDate && birth != null) {
          return "Birthdate is Invalid!";
        } else if (
          !anniversaryDate &&
          !anniversaryDate &&
          anniversary != null
        ) {
          return "Anniversary Date is Invalid!";
        } else {
          return "Date is Invalid!";
        }
      }
    } else {
      return false;
    }
  };


  const addImportantDatesField = () => {
    const tempAdditionalDates = [...additionalDates];
    let newObj = {
      type: "CUSTOM",
      title: "",
      value: ""
    };
    tempAdditionalDates.push(newObj);
    setAdditionalDates(tempAdditionalDates);
  };

  const removeInputField = (index) => {
    const tempAdditionalDates = [...additionalDates];
    tempAdditionalDates.splice(index, 1);
    setAdditionalDates(tempAdditionalDates);
  };

  const removeMultiField = (field, index) => {
    let tempContactForm = { ...contactForm };
    let tempFieldNumbers = [...tempContactForm[field]];
    tempFieldNumbers.splice(index, 1);
    tempContactForm[field] = tempFieldNumbers;
    setContactForm(tempContactForm);
  };

  const handleChangeInputField = (key, value, index) => {
    const tempAdditionalDates = [...additionalDates];
    tempAdditionalDates[index][key] = value;
    setAdditionalDates(tempAdditionalDates);


    if (additionalDatesErrors[index] && additionalDatesErrors[index][key]) {
      const tempAdditionalDatesErrors = [...additionalDatesErrors];
      tempAdditionalDatesErrors[index][key] = "";
      setAdditionalDatesErrors(tempAdditionalDatesErrors);
    }
  };

  const validateFields = () => {
    let isValid = true;

    let additionalErrors = [];

    additionalDates.forEach((additionalDate) => {
      let err = {
        title: "",
        value: ""
      };
      if (additionalDate.title !== null && additionalDate.title !== undefined && additionalDate.title.trim() === "") {
        err.title = "Date title is required";
        window.showNotification('Error', 'Important date title cannot be empty');
        isValid = false;
      }

      if (additionalDate.value !== null && additionalDate.value !== undefined && additionalDate.value.trim() === "") {
        err.value = "Date is required";
        window.showNotification('Error', 'Important date value cannot be empty');
        isValid = false;
      }

      additionalErrors.push(err);
    });
    setAdditionalDatesErrors(additionalErrors);
    return isValid;
  };

  const isInvalidFields = (field) => {
    let hasInvalid = false;
    if (field === 'number') {
      const tempContactForm = { ...contactForm };
      const tempFieldData = [...tempContactForm['numbers']];
      tempFieldData.forEach((additionalNumber) => {
        if (additionalNumber['number'] !== '' && !Utils.validateMobileNumber(additionalNumber['number'])) {
          hasInvalid = true;
        }
      });

    } else {
      const tempContactForm = { ...contactForm };
      const tempFieldData = [...tempContactForm['emails']];
      tempFieldData.forEach((additionalEmail) => {
        if (additionalEmail['email'] !== '' && !Utils.validateEmail(additionalEmail['email'])) {
          hasInvalid = true;
        }
      });
    }
    return hasInvalid;
  }

  const haveRequiredFields = () => {
    let hasNumber = false;
    let hasEmail = false;

    const tempContactForm = { ...contactForm };
    const tempFieldData = [...tempContactForm['numbers']];
    tempFieldData.forEach((additionalNumber, index) => {
      if (index === 0 && additionalNumber['number'] !== '') {
        hasNumber = true;
      }
    });

    const tempFieldEmailData = [...tempContactForm['emails']];
    tempFieldEmailData.forEach((additionalEmail, index) => {
      if (index === 0 && additionalEmail['email'] !== '') {
        hasEmail = true;
      }
    });

    if (hasNumber || hasEmail) {
      return true;
    } else {
      return false;
    }
  }

  const addContactCallback = (showModal = null, success = null, contactId = '') => {
    if (showModal !== undefined && showModal != null) {
      props.onClose();
    }
    if (success !== undefined && success != null && success) {
      if (contactId !== '') {
        history.push('/contacts/' + contactId)
      }
    }
  }

  const submitNewContact = () => {
    let validation = isDateInvalid(birthDate, anniversaryDate);
    if (validation) {
      window.showNotification('Error', validation);
      return;
    }

    if (!validateFields()) return;

    if (!haveRequiredFields()) {
      window.showNotification('Error', 'Email and Number, Either of them are necessary');
    } else if (isInvalidFields('email')) {
      window.showNotification('Error', 'Email is Invalid!');
    }
    else if (isInvalidFields('number')) {
      window.showNotification('Error', 'Number is Invalid!');

    } else {
      let tempContactForm = { ...contactForm };
      tempContactForm['customFields'] = customFieldsData;
      tempContactForm['important_dates'] = additionalDates;
      try {
        submitSingleContactV2(tempContactForm)
          .then(response => {
            if (response.data.messageStatus === 'error') {
              window.showNotification('Error', response.data.html.email !== undefined ? response.data.html.email :
                response.data.html.number !== undefined ? response.data.html.number : 'Something went wrong');

              setSubmittingContact(false)

            } else {
              if (openTab === "CREATE_CONTACT" && from === "onboard"){
                markStepComplete({ key: "CREATE_CONTACT" })
                    .then((resp)=>{})
                    .catch((err)=>{})
                    .finally(()=>{
                      window.showNotification('SUCCESS', 'Contact added successfully!');
                      // props.refreshDataForUpdateContact()
                      addContactCallback(false, true, response.data.insertedContactId);
                      setSubmittingContact(false)
                    });
              }else {
                window.showNotification('SUCCESS', 'Contact added successfully!');
                // props.refreshDataForUpdateContact()
                addContactCallback(false, true, response.data.insertedContactId);
                setSubmittingContact(false)
              }
            }
          })
      } catch (e) {
        console.log(e)
        setSubmittingContact(false)
      }
    }
  };

  const toggleAddress = () => {
    setShowAddress((prev) => !prev);
  };

  const appendEmail = () => {
    const emailObj = {
      email: ""
    };
    const tempContactForm = { ...contactForm };
    tempContactForm.emails.push(emailObj);
    setContactForm(tempContactForm);
  };

  const appendNumber = () => {
    const numberObj = {
      number: "",
      number_state: "mobile",
    };
    const tempContactForm = { ...contactForm };
    tempContactForm.numbers.push(numberObj);
    setContactForm(tempContactForm);
  };

  const handleChangeDynamicInput = (field, key, value, index) => {
    if (field === 'numbers' && key=== 'number') {
      if (!Utils.checkLetterExistInText(value) || value === '') {
        if (value.length > 0 && value[0] !== '1') {
          value = 1 + value;
        }
      } else {
        try {
          value = value.replace(/[^0-9]+/g, '')
          if (value.length > 0 && value[0] !== '1') {
            value = 1 + value;
          }
        }
        catch (e) {
          console.log(e)
        }
      }

      if (value.length > 11) {
        value = value.slice(0, 11);
      }
    }
    const tempContactForm = { ...contactForm };
    const tempFieldData = [...tempContactForm[field]];
    tempFieldData[index][key] = value;
    tempContactForm[field] = tempFieldData;
    setContactForm(tempContactForm);
  };

  const makeAddress = () => {
    let address = "";

    address = contactForm.address;

    if (contactForm.city) {
      if (address) {
        address += " ";
      }
      address += contactForm.city;
    }

    if (contactForm.state) {
      if (address) {
        address += " ";
      }
      address += contactForm.state;
    }

    if (contactForm.country) {
      if (address) {
        address += " ";
      }
      address += contactForm.country;
    }

    if (contactForm.zip) {
      if (address) {
        address += " ";
      }
      address += contactForm.zip;
    }

    return address;
  }

  const classes = useStyles();
  const placeholderColor = "#929da9";
  const activeColor = "#36454F";
  const placeholderColorForDate= "rgb(8 40 82 / 42%)";
  const imgSrc = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/smart_list/1.0.0/smart_lists_icon.png`
  return (
    <GlobalModalV2
      open={props.open}
      title={
        <div className={classes.titleContainer}>
          <div className={classes.logoContainer}>
          <img src={imgSrc} alt="logo" className={classes.logo} />
          </div> 
          <h2 style={{ color: "#000" }}>New Contact</h2>
        </div>
      }
      onClose={props.onClose}
      buttonText={"Save"}
      hideFooter={currentContact >= contactLimit ? true : submittingContact}
      handleSubmit={submitNewContact}
      className={"global-large-modal main-add-contact-modal main-add-contact-modal-v2"}
    >
      {!isLoading && (
        <Fragment>
          {currentContact < contactLimit && (
            <div className="add-contact-content-body awesome__scroll_bar" style={{paddingBottom: "38px",overflowX:'hidden'}}>
              <Box mb={2}>
                <Grid container spacing={2} style={{ paddingInline: 5 }}>
                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                      style={{ border: "none", padding: 0 }}
                    >
                      <div style={{ width: "100%" }}>
                        <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                          Lead Source
                        </Typography>
                      </div>


                      <BasicSelect
                        fullWidth
                        name="leadSource"
                        options={leadSources}
                        defaultText="Select lead source"
                        value={contactForm.selected_source}
                        onChange={(e) => handleSelectChange(e.target.value)}
                        mapping={{
                          label: "source_title",
                          value: "id"
                      }}
                      />

                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                        Lead Type
                      </Typography>

                      <LeadType
                        leadType={contactForm.lead_type}
                        onChange={handleInputField}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      className={`${classes.labelMarginBottom} ${classes.formLabel}`}
                      style={{ height: "auto" }}
                    >
                      Company Name (Optional)
                    </Typography>
                    <BasicInput
                      fullWidth
                      placeholder="Enter Company Name"
                      value={contactForm.company_name}
                      onChange={(event) => handleInputField("company_name", event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className={`${classes.labelMarginBottom} ${classes.formLabel}`}
                      style={{ height: "auto" }}
                    >
                      First Name
                    </Typography>
                    <BasicInput
                      fullWidth
                      placeholder="Enter First Name"
                      value={contactForm.first_name}
                      onChange={(event) => handleInputField("first_name", event.target.value)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="body1"
                      className={`${classes.labelMarginBottom} ${classes.formLabel}`}
                      style={{ height: "auto" }}
                    >
                      Last Name
                    </Typography>
                    <BasicInput
                      fullWidth
                      placeholder="Enter Last Name"
                      value={contactForm.last_name}
                      onChange={(event) => handleInputField("last_name", event.target.value)}
                    />
                  </Grid>

                  {
                    contactForm.numbers.map((number, index) => (
                      <Grid item xs={12} key={index}>
                        <Grid item xs={12}>
                          {
                            index === 0 && (
                              <Box
                                style={{ border: "none", padding: 0 }}
                              >
                                <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }} >
                                  Phone Number(s)
                                </Typography>
                              </Box>
                            )
                          }

                          <Grid container spacing={2}>
                            <Grid item xs={6} className="mask-numbr-input">
                              <BasicMaskedInput
                                mask={['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                className={`${classes.phoneNumberInput} MuiInputBase-input`}
                                placeholder="Enter Phone Number"
                                guide={true}
                                keepCharPositions={true}
                                id={"number-input-field" + index}
                                onChange={(event) => { handleChangeDynamicInput("numbers", "number", event.target.value, index); }}
                                value={number.number}
                              />
                              
                            </Grid>
                            <Grid item xs={6}>
                              <Box style={{ border: "none", padding: 0 }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                <BasicSelect
                        fullWidth
                        name="numberState"
                        options={PHONE_TYPES}
                        value={number.number_state}
                        onChange={(event) => { handleChangeDynamicInput("numbers", "number_state", event.target.value, index); }}
                        mapping={{
                          label: "label",
                          value: "value"
                      }}
                      />
                                  {/* <Select
                                    fullWidth
                                    className={classes.select}
                                    name="numberState"
                                    defaultValue={""}
                                    value={number.number_state}
                                    displayEmpty
                                    onChange={(event) => { handleChangeDynamicInput("numbers", "number_state", event.target.value, index); }}
                                    input={<BasicInputSelect  style={{ color: `${!number.number_state ? placeholderColor : activeColor}` }}/>}
                                    inputProps={{ "aria-label": "Without label" }}
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {
                                      PHONE_TYPES.map((phoneType) => (
                                        <MenuItem
                                          key={phoneType.value}
                                          className="dropdownhelper-menuitem-class"
                                          value={phoneType.value}
                                        >
                                          {phoneType.label}
                                        </MenuItem>
                                      ))
                                    }
                                  </Select> */}
                                  {index === 0 ? (
                                    <div className={classes.addButtonStyle} onClick={appendNumber}>
                                      {IconList.plusBoldSVG}
                                    </div>
                                  )
                                    :
                                    (
                                      <IconButton
                                        size='small'
                                        className=""
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                          removeMultiField("numbers", index);
                                        }}
                                      >
                                        <DeleteForever color='error' />
                                      </IconButton>
                                    )
                                  }

                                </div>

                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}

                  {
                    contactForm.emails.map((email, index) => (
                      <Grid item xs={12} key={index}>
                        {
                          index === 0 && (
                            <Box style={{ border: "none", padding: 0 }} >
                              <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                                Email Address(s)
                              </Typography>
                            </Box>
                          )
                        }
                        <div style={{ display: "flex", gap: "10px" }}>
                          <BasicInput
                            fullWidth
                            placeholder="Email Address"
                            value={email.email}
                            onChange={(event) => { handleChangeDynamicInput("emails", "email", event.target.value, index); }}
                          />
                          {index === 0 ? (
                            <div className={classes.addButtonStyle} onClick={appendEmail}>
                              {IconList.plusBoldSVG}
                            </div>
                          )
                            :
                            (
                              <IconButton
                                size='small'
                                className=""
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                  removeMultiField("emails", index);
                                }}
                              >
                                <DeleteForever color='error' />
                              </IconButton>
                            )
                          }
                        </div>
                      </Grid>
                    ))
                  }

                  {
                    showAddress ? (
                      <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "2px" }}>
                          <Button
                            variant="contained"
                            className={classes.manualEntryButton}
                            onClick={toggleAddress}
                            style={{ margin: 0, padding: "6px 14px", textTransform: "none" }}
                          >
                            Address Search
                          </Button>
                        </Box>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Box className={classes.googlePlacesAutocomplete} style={{ margin: 0 }}>
                          <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                            Address Search
                          </Typography>
                          <Box sx={{ display: "flex", justifyContent: "center", gap: "10px", paddingTop: "4px" }}>
                            <div style={{ width: "100%" }}>
                              <GooglePlacesAutocomplete
                                apiKey={GOOGLE_API_KEY}
                                autoFocus={false}
                                autocompletionRequest={{
                                  componentRestrictions: {
                                    country: ["us", "ca"],
                                  },
                                }}
                                selectProps={{
                                  value,
                                  onChange: async (e) => {
                                    await handleAddress(e.value);
                                  },
                                  placeholder: <span style={{ color: "rgb(8 40 82 / 42%)",fontSize:"15px" }}>Start typing address here…</span>,
                                  classNamePrefix: "my-google-places-autocomplete",
                                }}
                              />
                            </div>
                            <div style={{ width: "2px", paddingTop: "8px" }}>
                              <span style={{ position: "relative", marginLeft: "-80px" }}>
                                {IconList.locationSVG}
                              </span>
                            </div>

                            <Button
                              variant="contained"
                              className={classes.manualEntryButton}
                              onClick={toggleAddress}
                              style={{ whiteSpace: "nowrap", paddingLeft: "14px", paddingRight: "14px", textTransform: "none" }}
                            >
                              Manual
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  }
                  {
                    showAddress ? (
                      <Fragment>
                        <Grid item xs={12} style={{ paddingTop: "0px" }}>
                          <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                            Address
                          </Typography>
                          <BasicInput
                            fullWidth
                            placeholder="Enter Address"
                            value={contactForm.address}
                            onChange={(event) => handleInputField("address", event.target.value)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                            City
                          </Typography>
                          <BasicInput
                            fullWidth
                            placeholder="Enter City"
                            value={contactForm.city}
                            onChange={(event) => handleInputField("city", event.target.value)}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                              State
                            </Typography>

                            <Select
                              fullWidth
                              className={classes.select}
                              name="state"
                              value={getState(contactForm.state) ? contactForm.state : ""}
                              displayEmpty
                              onChange={(event) => handleInputField("state", event.target.value)}
                              input={<BasicInputSelect style={{ color: `${!getState(contactForm.state) ? placeholderColor : activeColor}` }}/>}
                              inputProps={{ "aria-label": "Without label" }}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                            >
                              <MenuItem
                                className={`dropdownhelper-menuitem-class ${classes.menuItem}`}
                                value=""
                              >
                                Select State
                              </MenuItem>

                              {US_STATE_LIST.map((state) => (
                                <MenuItem
                                  key={state.value}
                                  className={`dropdownhelper-menuitem-class ${classes.menuItem}`}
                                  value={state.value}
                                >
                                  {state.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                            Country
                          </Typography>
                          <BasicInput
                            fullWidth
                            placeholder="Enter Country"
                            value={contactForm.country}
                            onChange={(event) => handleInputField("country", event.target.value)}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                            Zip Code:
                          </Typography>
                          <BasicInput
                            fullWidth
                            placeholder="Enter Zip Code"
                            value={contactForm.zip}
                            onChange={(event) => handleInputField("zip", event.target.value)}
                          />
                        </Grid>
                      </Fragment>
                    ) : (
                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <div style={{ fontSize: "12px" }}>{makeAddress()}</div>
                      </Grid>
                    )
                  }
                </Grid>
              </Box>

              <Box mb={0}>
                <Box mb={0} style={{ border: "none", padding: "0px" }}>
                  <div style={{padding: "0px 6px"}}>
                  <Box className={classes.headlineWrapper}>
                    <Typography variant="body1">
                      Additional Info
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      columnGap: 20,
                    }}
                  >
                    {
                      customFields &&
                      Array.isArray(customFields) &&
                      customFields.map((each, index) => {
                        return renderEachCustomField(
                          each,
                          index,
                          handleCustomFieldChange,
                          customFieldsData
                        );
                      })
                    }
                  </Box>
                  </div>

                  {/* // Dates */}
                  <Grid container spacing={2} style={{ paddingInline: 5, marginTop: "2px" }}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          flex: 1,
                        }}
                      >
                        <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }} >
                            Birthday
                        </Typography>
                        <BasicInput
                          fullWidth
                          type="date"
                          style={{color:`${(
                            !birthDate) ?  
                            placeholderColorForDate : activeColor }`
                            }}
                          name="birth_date"
                          onChange={(e) => {
                            setBirthDate(e.target.value);
                            handleInputField("birth_date", e.target.value);
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                          flex: 1,
                        }}
                      >
                        <Typography variant="body1" className={classes.formLabel} style={{ height: "auto" }}>
                          Anniversary
                        </Typography>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <BasicInput
                            fullWidth
                            type="date"
                            style={{color:`${(
                              !anniversaryDate) ?  
                              placeholderColorForDate : activeColor }`
                              }}
                            name="anniversary_date"
                            onChange={(e) => {
                              setAnniversaryDate(e.target.value);
                              handleInputField("anniversary_date", e.target.value);
                            }}
                          />
                          <div className={classes.addButtonStyle} onClick={addImportantDatesField}>
                            {IconList.plusBoldSVG}
                          </div>
                        </div>
                      </Box>
                    </Grid>
                    {
                      additionalDates.map((additionalDate, index) => (
                        <>
                          <Grid item xs={12} md={6} >
                            <Box className={classes.flexColumn} sx={{ gap: 5, flex: 1 }} style={{ border: "none", padding: 0, marginTop: "0px" }}>
                              <BasicInput
                                fullWidth
                                type='text'
                                value={additionalDate.title}
                                placeholder={'Add a title here'}
                                onChange={(event) => { handleChangeInputField('title', event.target.value, index) }}
                              />
                              {
                                (additionalDatesErrors[index] && additionalDatesErrors[index].title) &&
                                <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalDatesErrors[index].title}</span>
                              }
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <div style={{ display: "flex", gap: "10px" }}>
                              <Box className={classes.flexColumn} sx={{ gap: 5, flex: 1 }} style={{ border: "none", padding: 0, marginTop: "0px" }}>
                                <BasicInput
                                  fullWidth
                                  type='date'
                                  style={{color:`${(
                                    !additionalDate.value) ?  
                                    placeholderColor : activeColor }`
                                    }}
                                  value={additionalDate.value}
                                  onChange={(event) => { handleChangeInputField('value', event.target.value, index) }}
                                />
                                {
                                  (additionalDatesErrors[index] && additionalDatesErrors[index].value) &&
                                  <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalDatesErrors[index].value}</span>
                                }
                              </Box>
                              <IconButton
                                size='small'
                                className=""
                                style={{ marginLeft: "5px" }}
                                onClick={() => {
                                  removeInputField(index);
                                }}
                              >
                                <DeleteForever color='error' />
                              </IconButton>
                            </div>
                          </Grid>
                        </>
                      ))
                    }
                  </Grid>
                  {/* // Dates */}

                </Box>
              </Box>

            </div>
          )
          }

          {
            currentContact >= contactLimit && (
              <Upgrade
                isLoading={isLoading}
                handleChange={() => props.onClose()}
              />
            )
          }

          {
            submittingContact && (
              <GlobalAddContactModalSkeleton/>
            )
          }
        </Fragment>
      )}

      {isLoading && <GlobalAddContactModalSkeleton/>}
    </GlobalModalV2>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.addContactReducer.contact,
    userTagList: state.addContactReducer.userTagListAddContact,
    storedSourceList: state.addContactReducer.sourceListAddContact,
    storedCustomField: state.addContactReducer.userCustomFieldsAddContact,
    assignedContactListInfo: state.addContactReducer.assignedContactListInfo,
    contactListParams: state.addContactReducer.contactListParams,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
    storeUserTagList: (params) => dispatch(setUserTagList(params)),
    fetchUserSourceList: (params, callback) => dispatch(getUserSourceList(params, callback)),
    storeUserSourceList: (params) => dispatch(setUserSourceList(params)),
    fetchUserCustomFields: (params, callback) => dispatch(getUserCustomFieldList(params, callback)),
    storeUserCustomFields: (params) => dispatch(setUserCustomFieldList(params)),
    storeUserCustomFieldsNew: (params) => dispatch(storeUserCustomFieldsListNew(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddContactModal);

export const renderEachCustomField = (data, index, onChange, previousData) => {
  const placeholderColor = "rgb(8 40 82 / 42%)";
  const activeColor = "#36454F";
  let type_id = data.type_id;
  let _values =
    typeof previousData[index] !== "undefined" && previousData[index] != null
      ? typeof previousData[index].value !== "undefined"
        ? type_id === CustomFieldType.CHECKBOX
          ? previousData[index].value.split(",")
          : previousData[index].value
        : ""
      : "";

  const handleOnChange = (e, self) => {
    if (
      type_id === CustomFieldType.NUMERIC ||
      type_id === CustomFieldType.ZIP_CODE
    ) {
      onChange(e.target.value, index, data.id, data?.max_length);
    } else if (type_id === CustomFieldType.CHECKBOX) {
      let previous_value =
        typeof previousData[index] !== "undefined" &&
          previousData[index] != null
          ? typeof previousData[index].value !== "undefined"
            ? previousData[index].value
            : ""
          : "",
        new_value = "";
      if (e.target.checked) {
        if (previous_value.trim() === "") {
          new_value = e.target.value;
        } else {
          new_value = previous_value + "," + e.target.value;
        }
      } else {
        if (previous_value.indexOf(e.target.value) !== -1) {
          let value_array = previous_value.split(",");
          value_array.forEach((each_data, each_index) => {
            if (each_data !== e.target.value) {
              if (new_value === "") {
                new_value += each_data;
              } else {
                new_value = new_value + "," + each_data;
              }
            }
          });
        }
      }
      onChange(new_value, index, data.id);
    } else if (type_id === CustomFieldType.DATE) {
      onChange(moment(e.target.value).format("YYYY-MM-DD"), index, data.id);
    } else if (type_id === CustomFieldType.DATETIME) {
      onChange(
        moment(e.target.value).format("MM-D-YYYY HH:mm:ss"),
        index,
        data.id
      );
    } else if (type_id === CustomFieldType.RADIO) {
      onChange(e.target.value, index, data.id);
    } else {
      onChange(e.target.value, index, data.id);
    }
  };

  if (
    type_id === CustomFieldType.TEXT ||
    type_id === CustomFieldType.MULTILINE_TEXT ||
    type_id === CustomFieldType.NUMERIC ||
    type_id === CustomFieldType.DATE ||
    type_id === CustomFieldType.DATETIME ||
    type_id === CustomFieldType.PHONE ||
    type_id === CustomFieldType.ZIP_CODE ||
    type_id === CustomFieldType.WEBSITE ||
    type_id === CustomFieldType.CHECKBOX ||
    type_id === CustomFieldType.RADIO ||
    type_id === CustomFieldType.SELECT
  ) {
    return (
      <div
        className="input-field col s12 m12 custom_input_forms custom_contact_field"
        key={`${type_id} ${index}`}
        style={{ marginBottom: 0, marginTop: "8px", paddingBottom: "8px" }}
      >
        {/* <label
          htmlFor={`custom-field-${index}`}
          style={{ left: "0", marginBottom: 0, position: "relative", height: "auto", fontWeight: "700 !important", color: "#181f48" }}
        >
          {data?.title}
        </label> */}
        <Typography variant="body1" style={{ left: "0", marginBottom: 1, position: "relative", height: "auto", fontWeight: "600", color: "#181f48"}}>
          {data?.title}
        </Typography>

        {(type_id === CustomFieldType.TEXT ||
          type_id === CustomFieldType.PHONE) && (
            <BasicInput
            fullWidth
              type="text"
              id={`custom-field-${index}`}
              value={
                previousData[index] !== undefined && previousData[index] != null
                  ? previousData[index].value
                  : ""
              }

              placeholder={"Enter " + data?.title}
              maxLength={data?.max_length}
              onChange={(e) => handleOnChange(e)}
           
            />
          )}

        {type_id === CustomFieldType.MULTILINE_TEXT && (
          <textarea
            rows="5"
            className={"custom-field-textarea MuiInputBase-input"}
            id={`custom-field-${index}`}
            placeholder={"Enter " + data?.title}
            onChange={(e) => handleOnChange(e)}
            style={{ marginTop: 10, margin: "0px !important", fontSize: "15px",color:`${(
              !previousData?.[index]?.value) ?  
              placeholderColor : activeColor }` }} 
          >
            {previousData?.[index]?.value}
          </textarea>
        )}

        {(type_id === CustomFieldType.NUMERIC ||
          type_id === CustomFieldType.ZIP_CODE) && (
            <BasicInput
            fullWidth
              type="number"
              id={`custom-field-${index}`}
              value={previousData?.[index]?.value}
              placeholder={"Enter " + data?.title}
              maxLength={data?.max_length}
              pattern="[0-9]{11}"
              onChange={(e) => handleOnChange(e)}
        
            />
          )}

        {type_id === CustomFieldType.DATE && (
          <input
            type="date"
            id={`custom-field-${index}`}
            value={previousData?.[index]?.value}
            maxLength={data?.max_length}
            pattern="[0-9]{11}"
            onChange={(e) => handleOnChange(e)}
            style={{ height: "2.5rem", margin: "0px !important", fontSize: "14px",color:`${(
              !previousData?.[index]?.value) ?  
              placeholderColor : activeColor }` }}
            className="MuiInputBase-input"
          />
        )}

        {type_id === CustomFieldType.DATETIME && (
          <BasicInput
            fullWidth
            type="datetime-local"
            id={`custom-field-${index}`}
            value={previousData?.[index]?.value ? moment(previousData?.[index]?.value).format("YYYY-MM-DD HH:mm:ss") : ""}
            placeholder={"Enter " + data?.title}
            maxLength={data?.max_length}
            pattern="[0-9]{11}"
            onChange={(e) => handleOnChange(e)}
          />
        )}

        {type_id === CustomFieldType.WEBSITE && (
          <BasicInput
          fullWidth
            type="url"
            id={`custom-field-${index}`}
            value={previousData?.[index]?.value}
            placeholder={"Enter " + data?.title}
            maxLength={data?.max_length}
            pattern="[0-9]{11}"
            onChange={(e) => handleOnChange(e)}
          />
        )}

        {type_id === CustomFieldType.CHECKBOX && (
          <div className="check-box-input-groups">
            <FormGroup row className="custom_check_box_container">
              {data.default_values &&
                Array.isArray(data.default_values) &&
                data.default_values.map((each, each_index) => {
                  let isChecked = _values.indexOf(each) !== -1 ? true : false;
                  return (
                    <FormControlLabel
                      className="custom_check_box"
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleOnChange}
                          name={`${index}-${data.personalize_tag}[]`}
                          value={each}
                          style={{ padding: "12px" }}
                        />
                      }
                      label={each}
                    />
                  );
                })}
            </FormGroup>
          </div>
        )}

        {type_id === CustomFieldType.RADIO && (
          <div className="radio-button-input-groups">
            <RadioGroup
              row
              aria-label={index + "-" + data.personalize_tag}
              name={index + "-" + data.personalize_tag}
              value={_values}
              onChange={handleOnChange}
            >
              {data.default_values &&
                Array.isArray(data.default_values) &&
                data.default_values.map((each, each_index) => {
                  return (
                    <FormControlLabel
                      value={each}
                      control={<Radio style={{ padding: "12px" }} />}
                      label={each}
                    />
                  );
                })}
            </RadioGroup>
          </div>
        )}

        {type_id === CustomFieldType.SELECT && (
          <select
            className="form-control h-100 d-block"
            placeholder={"Enter " + data.title}
            name={`add_contact_custom_field${data.personalize_tag}`}
            onChange={(e) => handleOnChange(e)}
            style={{ marginTop: 10, height: "2.5rem", fontSize: "15px",color:`${(
              data.default_values) ?  
              placeholderColor : activeColor }` }}
          >
            <option value="" key={-1}>
              {data.title}
            </option>
            {data.default_values &&
              Array.isArray(data.default_values) &&
              data.default_values.map((each, each_index) => {
                if (
                  previousData[index] !== undefined &&
                  previousData[index] != null &&
                  previousData[index].value === each
                ) {
                  return (
                    <option
                      value={each}
                      key={`custom-select-${each_index}`}
                      selected
                    >
                      {each}
                    </option>
                  );
                } else
                  return (
                    <option value={each} key={`custom-select-${each_index}`}>
                      {each}
                    </option>
                  );
              })}
          </select>
        )}
      </div>
    );
  }

  return null;
};

import React, { useState } from "react";
import CampaignPrivacyTabs from "./CampaignPrivacyTabs";
import CampaignFilter from "./CampaignFilter";
import CampaignList from "./CampaignList";



const CampaignBody = ({campaignList, isCampaignLoading, totalCampaignCount, selectedCampaign, setSelectedCampaign, filters, setFilters, activeTab, handleTabChange, page, setPage, perPage, setPerPage }) => {
  const [isStatusCollapsed, setIsStatusCollapsed] = useState(true);
  return (
    <div style={{ backgroundColor: "#fff", width: "100%", padding: "4px 10px 10px",  borderRadius: 8, }}>
        <CampaignPrivacyTabs activeTab={activeTab} handleTabChange={handleTabChange}  />
        
        <CampaignFilter
          activeTab={activeTab}
          filters={filters}
          setFilters={setFilters}
          isStatusCollapsed={isStatusCollapsed}
          setIsStatusCollapsed={setIsStatusCollapsed} 
        />

        <CampaignList 
          campaignList={campaignList}
          isCampaignLoading={isCampaignLoading}
          totalCampaignCount={totalCampaignCount}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          isStatusCollapsed={isStatusCollapsed}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage} 
        />
    </div>
  );
};

export default CampaignBody;

import React, {useRef, useState, useEffect} from "react";
import {connect} from "react-redux";
import {sendMessage} from "../../../api/contactApi";
import SavedReply from "./common/SavedReply";
import CoreConstants from "./constants/CoreConstants";
import GlobalCustomFieldGrouped from "./globals/GlobalCustomFieldGrouped/Index";
import Utils from "../Helpers/Utils";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";
import SavedReplyWithGlobalPrivacy from "./common/SavedReplyWithGlobalPrivacy";

const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;

const App = (props) => {
    const [contact, setContact] = useState(props.selectedContact);
    const [savedReplyModal, setSavedReplyModal] = useState(false);
    const [chatText, setChatText] = useState('');
    const textInput = useRef(null);

    const [sending, setSending] = useState(false)

    useEffect(() => {
        setContact(props.selectedContact);
    }, [props.selectedContact]);


    const handlePersonalize = (event) => {
        let start = textInput.current.selectionStart;
        let end = textInput.current.selectionEnd;
        let text = textInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        // let newText = (before + event.target.value + after);
        let newText = (before + event+ after);

        setChatText(newText);

        textInput.current.selectionStart = textInput.current.selectionEnd = start + newText.length;
        textInput.current.focus();
    }

    const sendChat = (event, callback = () => {}) => {
        if(event){
            event.preventDefault();
        }
        if(sending){
            return
        }

        if(chatText == ''){
            window.showNotification("ERROR","Message is required !" )
            return
        }
        setSending(true)

        let formData = new FormData();
        formData.append('contact_id', contact.id);
        formData.append('origin', 3);
        formData.append('message', chatText);
        formData.append('messageLevel', 'chat');

        sendMessage(formData)
            .then(response => {
                setSending(false)
                if (response.data.status == 'success') {
                    window.appendConversation(response.data.fulldata)
                    if(props.closeTab != undefined){
                        props.closeTab()
                    }
                    callback(true);
                }
                else{
                    if(response.data.html ===  "Contact is blocked"){
                        if(window.showNotification !== undefined){
                            window.showNotification("ERROR", "Contact is blocked")
                        }
                    }
                    else{
                        if(window.showNotification !== undefined){
                            window.showNotification("ERROR", "Something went wrong. Try again later")
                        }
                    }
                    callback(false);
                }
            })
    }

    const useMessageTemplate = data => {
        setSavedReplyModal(false);

        setChatText(data.message);
    }

    window.handleChatSend = (callback) => {
        sendChat(null, callback)
    }

    return (
        <div id="chatTab" className="col s12 inbox__chat__msg__wr">
            <div className="">
            <div className="tiny-mce">
                <div className="mb-0 row">
                    <div className="inbox__chat__msg__area__wr">
                        <div className="inbox__chat__btn__group">
                        <div className="inbox__chat__btn__group_single">
                            <GlobalCustomFieldGrouped className="inbox__chat__global__personalized" handleSelect={handlePersonalize}/>
                        </div>
                        <div className="inbox__all__btn__group_single">
                        <div className="quick-reply__alt__chat" onClick={(e) => {e.preventDefault(); setSavedReplyModal(true)}}>
                                <div className="reply__alt__chat__btn">
                                    <span >
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159"/>
                                        </svg>
                                    </span>
                                    <span>Use Quick Reply</span>
                                </div>
                            </div>
                        </div>
                            {
                                savedReplyModal &&
                                (isAgencyTypeInsurance 
                                    ?
                                        <SavedReplyWithGlobalPrivacy 
                                            openSavedReplyModal={savedReplyModal} setOpenSavedReplyModal={setSavedReplyModal} useMessageTemplate={useMessageTemplate} templateType={CoreConstants.MESSAGE_TEMPLATE.SMS}
                                        />
                                    : 
                                        <SavedReply 
                                            openSavedReplyModal={savedReplyModal} setOpenSavedReplyModal={setSavedReplyModal} useMessageTemplate={useMessageTemplate} templateType={CoreConstants.MESSAGE_TEMPLATE.SMS}
                                        />
                                )
                                
                            }
                        </div>
                        <div className="inbox__chat__tiny__mce__wr" style={{minHeight:'150px'}}>
                            <div className="inbox__chat__tiny__mce__container">
                            <textarea className="inbox__chat__tiny__text__area"  placeholder="Type a message" name="chatText" ref={textInput} onChange={(event) => setChatText(event.target.value)} value={chatText}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="send-button-area">
                <div className="mb-0 row">
                    <div className="col s8">
                        
                    </div>
                    <div className="col s4">
                        <div className="send-message-btn right p-relative">
                            <a href="#" onClick={(event) => sendChat(event)}><i className="material-icons left">near_me</i>Send</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedContact: state.inboxReducer.selectedContact,
    };
};

const Chat = connect(mapStateToProps, null)(App);

export default Chat;
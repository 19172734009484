import React, { useEffect, useRef, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { getVirtualNumbers } from "./inboxAction";
import { connect } from "react-redux";
import AudioRecord from "./AudioRecord";
import Utils from "../Helpers/Utils";
import { sendMessage } from "../../../api/contactApi";
import SavedReply from "./common/SavedReply";
import CoreConstants from "./constants/CoreConstants";
import ClearIcon from "@material-ui/icons/Clear";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import dncNoCall from "./common/dncNoCall";
import "./inbox-new.css";
import SavedReplyWithGlobalPrivacy from "./common/SavedReplyWithGlobalPrivacy";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";

const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;

const Voice = (props) => {
  const [contact, setContact] = useState(props.selectedContact);
  const [virtualNumbers, setVirtualNumbers] = useState(props.virtualNumbers);
  const [selectedVirtualNumber, setSelectedVirtualNumber] = useState("");
  const [selectedVoiceTemplateId, setSelectedVoiceTemplateId] = useState(null);
  const [savedReplyModal, setSavedReplyModal] = useState(false);
  const AudioRecorder = useRef(null);
  const [sending, setSending] = useState(false);
  const [recordedVoiceData, setRecordedVoiceData] = useState(null);
  const [useTemplate, setUseTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);

  useEffect(() => {
    if (props.virtualNumbers.length === 0) {
      props.getVirtualNumbers({ userid: 1 });
    } else {
      setSelectedVirtualNumber(props.virtualNumbers[0]);
      setVirtualNumbers(props.virtualNumbers);
    }
  }, [props.virtualNumbers]);

  useEffect(() => {
    setContact(props.selectedContact);
  }, [props.selectedContact]);

  const renderVirtualNumbers = () => {
    if (virtualNumbers.length) {
      let virtualNumberOptions = [];
      virtualNumbers.map((row, index) => {
        virtualNumberOptions.push(
          <MenuItem
            style={{ padding: "4px 10px", display: "block" }}
            value={row}
          >
            {row.virtual_number}{" "}
          </MenuItem>
        );
      });

      return virtualNumberOptions;
    }
  };

  const voiceRecordCallback = (voiceData) => {
    setRecordedVoiceData(voiceData);
  };

  const sendVoiceMail = (bypass = false) => {
    var shouldNotGo = true;

    if (!bypass) {
      if (
        Utils.getAccountData("carrierLookup") &&
        props.contact &&
        props.contact.number
      ) {
        if (
          props.contact.contact_additional_informations &&
          props.contact.contact_additional_informations.dnc_status ===
            "DO_NOT_CALL"
        ) {
          dncNoCall({
            takeRisk: () => {
              shouldNotGo = false;
              sendVoiceMail(true);
            },
            cancelButtonText: "Don't Send",
          });
          shouldNotGo = true;
        } else {
          shouldNotGo = false;
        }
      } else {
        shouldNotGo = false;
      }
    } else {
      shouldNotGo = false;
    }

    if (shouldNotGo) {
      return;
    }

    if (sending) {
      return;
    }

    setSending(true);

    if (!useTemplate && !recordedVoiceData) {
      window.showNotification("ERROR", "No voice data is added");
      return false;
    }

    if (!selectedVirtualNumber) {
      window.showNotification("ERROR", "Please select virtual number");
      return false;
    }

    let formData = new FormData();
    formData.append("contact_id", contact.id);
    formData.append("origin", 3);
    formData.append("message", "");
    formData.append("messageLevel", "voice");
    formData.append(
      "virtual_number_id",
      selectedVirtualNumber ? selectedVirtualNumber.id : null
    );
    formData.append(
      "voice_template_id",
      selectedVoiceTemplateId ? selectedVoiceTemplateId : ""
    );
    if (!useTemplate && recordedVoiceData) {
      formData.append("voice_file", recordedVoiceData);
    } else {
      formData.append("voice_file_url", selectedTemplate);
    }
    formData.append("save_as_template", saveAsTemplate);
    formData.append("saveTemplate", saveAsTemplate);

    sendMessage(formData).then((response) => {
      setSending(false);
      if (response.data.status == "success") {
        if (response.data.conversation != undefined) {
          window.appendConversation(response.data.conversation);
        }
        if (props.closeTab != undefined) {
          props.closeTab();
        }
      } else {
        if (response.data.html === "The contact have no phone number.") {
          if (window.showNotification !== undefined) {
            window.showNotification(
              "ERROR",
              "The contact have no phone number."
            );
          }
        } else if (response.data.html === "Contact is blocked") {
          if (window.showNotification !== undefined) {
            window.showNotification("ERROR", "Contact is blocked");
          }
        } else {
          if (window.showNotification !== undefined) {
            window.showNotification(
              "ERROR",
              "Something went wrong. Try again later"
            );
          }
        }
      }
    });
  };

  const useMessageTemplate = (data) => {
    setSavedReplyModal(false);
    if (data.template_files === null) {
      setSelectedTemplate(data.video_file_url);
    } else {
      setSelectedTemplate(data?.template_files?.file);
      setSelectedVoiceTemplateId(data.template_file_id);
    }
    setUseTemplate(true);
    setRecordedVoiceData(null);
  };

  const callback = () => {
    setUseTemplate(false);
    setSelectedTemplate(null);
  };

  return (
    <div id="voiceTab" className="col s12 active inbox__voice__msg__wr">
      <div className="chat__bottom_wrapper">
        <div className="catagory-area">
          <div className="mb-0 row">
            <div className="inbox__voice__msg__area__wr">
              <div className="inbox__voice__btn__group">
                <div className="inbox__voice__btn__group_single">
                  <div className="inbox__voice__personilized">
                    <Select
                      labelId="virtual-number-select-label"
                      id="virtual-number-select"
                      value={selectedVirtualNumber}
                      onChange={(e) => setSelectedVirtualNumber(e.target.value)}
                      className={"virtual-number-select voice_number_select"}
                    >
                      {renderVirtualNumbers()}
                    </Select>
                  </div>
                </div>
                <div className="inbox__voice__btn__group_single">
                  <div
                    className="reply__alt__voice__btn"
                    onClick={(e) => {
                      e.preventDefault();
                      setSavedReplyModal(true);
                    }}
                  >
                    <span>
                      <svg
                        width="10"
                        height="15"
                        viewBox="0 0 10 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159" />
                      </svg>
                    </span>
                    <span> Use Quick Reply</span>
                  </div>
                  {
                    savedReplyModal &&
                      (isAgencyTypeInsurance 
                        ?
                            <SavedReplyWithGlobalPrivacy 
                              openSavedReplyModal={savedReplyModal}
                              setOpenSavedReplyModal={setSavedReplyModal}
                              useMessageTemplate={useMessageTemplate}
                              templateType={CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}
                            />
                        : 
                            <SavedReply
                              openSavedReplyModal={savedReplyModal}
                              setOpenSavedReplyModal={setSavedReplyModal}
                              useMessageTemplate={useMessageTemplate}
                              templateType={CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}
                            />
                      )

                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {selectedTemplate === null && (
          <AudioRecord
            ref={AudioRecorder}
            showAudio={recordedVoiceData}
            callback={callback}
            inbox={true}
            voiceRecordCallback={voiceRecordCallback}
          />
        )}
        <div
          className={`selected-template-content selected_audio_file ${
            selectedTemplate === null ? "d-none" : ""
          }`}
        >
          <audio
            controls="controls"
            src={selectedTemplate}
            className={`${selectedTemplate === null ? "d-none" : ""}`}
          />
          <div onClick={() => setSelectedTemplate(null)}>
            <ClearIcon />
          </div>
        </div>

        <div className="send-button-area">
          <div className="mb-0 row">
            <div className="col s8">
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={saveAsTemplate}
                      onChange={() => setSaveAsTemplate(!saveAsTemplate)}
                      name="save-as-template"
                    />
                  }
                  label="Save as quick reply"
                />
              </FormControl>
            </div>
            <div className="col s4">
              <div className="mt-0 send-message-btn right p-relative">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    sendVoiceMail();
                  }}
                >
                  <i className="material-icons left">near_me</i>
                  {sending ? "Sending" : "Send"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.rightsideReducer.contact,
    virtualNumbers: state.inboxReducer.virtualNumbers,
    personalizes: state.inboxReducer.personalizes,
    selectedContact: state.inboxReducer.selectedContact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVirtualNumbers: (params) => dispatch(getVirtualNumbers(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Voice);

import HttpRequest from "../../../../api/HttpRequest";
import { sendRequest } from "../../../../api/rootApi";
import Api from "../../../../constants/Api";


export const getCampaignListApi = async (payload) => {
    return HttpRequest.post(Api.getCampaignList, payload);
}

export const getCampaignFoldersApi = async (payload) => {
    return sendRequest("POST", Api.getCampaignFolders, payload);
}

export const fetchTeamMembers = async (payload) => {
    return sendRequest("post", Api.fetchTeamMembers, payload);
}

export const addContactsInCampaign = async (payload) => {
    return sendRequest("post", Api.addContactsInCampaign, payload);
}
import { Button, Slide } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { IconList } from "./IconList";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",

    "& .sub_title": {
      display: "flex",
      alignItems: "center",
    },

    "& .modal_img": {
      width: "60px",
      marginRight: "10px",
    },

    "& .modalNewFooter": {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      height: "93px",
      borderTop: "2px solid var(--gray_dark)",
      boxSizing: "border-box",
      padding: "10px 30px",

      "& .MuiButton-text": {
        padding: "6px 8px !important",
        borderRadius: "4px !important",
      },

      "& .newCancelButton": {
        color: "#000",
        backgroundColor: "#fff",
        padding: "6px 16px !important",
        border: "1px solid #ccc",
        width: "160px",
        marginRight: theme.spacing(1),
        textTransform: "capitalize",
        fontWeight: 600,
        "&:hover": {
          backgroundColor: "#f5f5f5",
        },
      },
      "& .newSendButton": {
        padding: "6px 16px !important",
        color: "#fff",
        width: "160px",
        textTransform: "capitalize",
        fontWeight: 600,
        backgroundColor: "#3C7EF3",
        "&:hover": {
          backgroundColor: "#3C7EF3",
        },
      },
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  action: {
    margin: "40px",
    display: "flex",
    justifyContent: "end",
    alignItems: "  center",
    gap: 10,
  },
  dealButton: {
    backgroundColor: "#445af3",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "6px 10px",
    border: "1px solid #445af3",
    "&:hover": {
      backgroundColor: "#001ff6",
      border: "1px solid #001ff6",
    },
    "&:focus": {
      backgroundColor: "#445af3",
    },
  },
  cancelButton: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "7px 20px",
    border: "1px solid #a4a4a4",
    "&:hover": {
      backgroundColor: "#f3f4f6",
    },
    "&:focus": {
      backgroundColor: "#ffffff",
    },
  },
}));

export default function GlobalModalForActivity(props) {
  const classes = useStyles();
  const modalBodyId = props.bodyId
    ? props.bodyId
    : "modal___body___container___id";

  const [show, setShow] = useState(props.open);

  const closeModal = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose(false);
    }, 500);
  };

  let extra_props = {};
  if (props.extra_props_active !== undefined) {
    extra_props["disableEnforceFocus"] = true;
    extra_props["disableAutoFocus"] = true;
  }

  const handleOnScroll = () => {
    if (props.handleOnScroll !== undefined) {
      props.handleOnScroll();
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={show}
        onClose={props.setOpenSMSModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
        onBackdropClick={closeModal}
        ModalProps={{
          disableEnforceFocus: true,
          disableAutoFocus: true,
        }}
        {...extra_props}
      >
        <Slide in={show} direction="left">
          <div
            className={`globalModalWrapper cardBordcastTab ${
              props.className !== undefined ? props.className : ""
            } ${props.hideFooter !== true ? "" : "global-modal-has-no-footer"}`}
          >
            <div id="globalModal">
              <div className="globalModalTitle d-flex justify-content-between align-items-center">
                <div className="sub_title">
                  {props.img && (
                    <img className="modal_img" src={props.img} alt={"icon"} />
                  )}
                  {typeof props.title === "string" ? (
                    <h2>{props.title}</h2>
                  ) : (
                    <>{props.title}</>
                  )}
                </div>
                {typeof props.extraButton != "undefined" && (
                  <div
                    className="extra-button"
                    onClick={props.onClickExtraButton}
                  >
                    {props.extraButton}
                  </div>
                )}
              </div>

              {/* Modal Body */}

              <div
                className="modalBody"
                style={{ position: "relative" }}
                id={modalBodyId}
                onScroll={handleOnScroll}
              >
                {props.children}
              </div>

              {/*Send Massage and close button*/}
              {props.hideFooter !== true &&
                (props.newFooter ? (
                  <div className="modalNewFooter">
                    <Button className="newCancelButton" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      className="newSendButton"
                      onClick={props.handleSubmit}
                    >
                      {props.buttonText}
                    </Button>
                  </div>
                ) : (
                  <div className="massage_send_close_button d-flex justify-content-end align-items-center">
                    <div className={classes.action}>
                      <button
                        onClick={() => {
                          closeModal();
                        }}
                        className={classes.cancelButton}
                      >
                        Cancel
                      </button>

                      <button
                        className={classes.dealButton}
                        onClick={props.handleSubmit}
                        style={{ width: "90px" }}
                        variant="contained"
                        disableElevation
                      >
                        <span style={{ fontSize: "16px", color: "#ffffff" }}>
                          Save
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  );
}

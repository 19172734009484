import React from 'react';
import Helper from '../../../../helpers/Helper';
import { IconHandler } from '../sidebar/IconHandler';
import { Collapse, FormControlLabel, makeStyles } from '@material-ui/core';
import { CustomRadio } from '../assignToCampaignStyles';
import { Icons } from '../Icons';

const each_campaign_list_stats_icon = [
    { index: 19, title: "Contacts", link: "/contacts?campaignId=" },
    {
      index: 20,
      title: "Contacted",
      link: '/contacts?contacted="contacted"&campaignId=',
    },
    {
      index: 21,
      title: "Responded",
      link: '/contacts?responded="responded"&campaignId=',
    },
    { index: 22, title: "Response Rate", link: "" },
    { index: 23, title: "Lead Flow", link: "/leadFlow?campaignid=" },
  ];

const eachCampaignStyles = makeStyles((theme) => ({
    root: {
        display: "flex", 
        alignItems: "center",

        "& .eachItem": {
            padding: "20px 32px",
            border: "1px solid rgba(148, 157, 178, 0.24)",
            borderRadius: "8px",
            flex: "1 1 auto",
            transition: "all 0.3s linear",
      
            "& .title": {
              fontSize: "20px",
              fontWeight: 600,
            },
      
            "& .description": {
              display: "flex",
              alignItems: "center",
              gap: 16,
              color: "#546376",
            },
      
            "& .campaignType": {
              position: "relative",
              "&:after": {
                position: "absolute",
                content: '""',
                right: -8,
                top: 3,
                height: 15,
                width: 1,
                background: "rgba(148,157,178,0.24)",
              },
            },
      
            "& .campaignDate": {
              display: "flex",
              alignItems: "center",
              gap: "5px",
            },
      
            "& .status": {
              display: "flex",
              alignItems: "center",
              gap: "16px",
              borderTop: "1px solid rgba(148, 157, 178, 0.24)",
              paddingTop: "16px",
              marginTop: "16px",
            },
      
            "& .statusItemWrapper": {
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px',
      
              "& .statsTitle": {
                  fontSize: '11px',
                  color: '#546376',
              }
          }
        },
    }

}));

const EachCampaignItem = ({ selectedCampaign, setSelectedCampaign, item: campaignItem, isStatusCollapsed }) => {
    const classes = eachCampaignStyles();

    const renderDate = () => {
      if (window.globalTimezoneConversionToDifferentTimezone !== undefined) {
        // let userTimelineRaw = Link.GetAccountData('userTimezoneRaw');
        return window.globalTimezoneConversionToDifferentTimezone(
          campaignItem.created_at,
          "UTC",
          campaignItem.timezone,
          "MM-DD-YYYY hh:mm:ss A"
        );
      }
      return campaignItem.created_at;
    };
  
    const getStatsValue = (type, item) => {

        let value = 0;
        if(type === 'Contacts'){
            if(item !== undefined && item != null){
                if(!Helper.isEmpty(item.total_contact)){
                    value = item.total_contact;
                }
            }
        }
        else if(type === 'Contacted'){
            if(item !== undefined && item != null){
                if(!Helper.isEmpty(item.total_contacted)){
                    value = item.total_contacted;
                }
            }
        }
        else if(type === 'Responded'){
            if(item !== undefined && item != null){
                if(!Helper.isEmpty(item.total_responded)){
                    value = item.total_responded;
                }
            }
        }
        else if(type === 'Response Rate'){
            if(item !== undefined && item != null){
                if(!Helper.isEmpty(item.total_contacted)){
                    if(item.total_contacted > 0){
                        value = `${Number(item.total_responded/item.total_contacted * 100).toFixed(2)}%`
                    }
                    else{
                        value = '0%';
                    }
                }
                else{
                    value = '0%';
                }
            }
            else{
                value = '0%';
            }
        }
        else if(type === 'Lead Flow'){
            if(item.lead_flow_connected_count !== undefined && item.lead_flow_connected_count != null){
                value = item.lead_flow_connected_count;
            }
        }
        return value;
    }
  
    const renderStats = () => {
      let bgColor = "#5463763b",
        textColor = "#546376";
      // if (state.selectedFolder != null) {
      //   bgColor = state.selectedFolder?.color;
      //   textColor = state.selectedFolder?.text_color;
      // }
      const view = [];
      each_campaign_list_stats_icon.forEach((statsItem, index) => {
        const icons = new IconHandler(textColor, parseInt(statsItem.index), bgColor);
        let stats_value = getStatsValue(statsItem.title, campaignItem); //will get this data from props
        view.push(
          <div
              className={'statusItemWrapper'}
              key={index}
              // onClick={() => goToLink(item.link)}
            >
              <div className="statsIcon">{icons.getIcon}</div>
              <div className="statsContent" style={{display: 'flex', flexDirection: 'column'}}>
                <span className="statsValue">
                  {stats_value}
                </span>
                <span className="statsTitle">
                  {statsItem.title}
                </span>
              </div>
            </div>
        );
      });
      return view;
    };

    const handleSelect = () => {
      setSelectedCampaign({ id: campaignItem.id, title: campaignItem.title });
    };
  
    return (
      <div className={classes.root}>
        <FormControlLabel
          control={
            <CustomRadio
              checked={selectedCampaign?.id === campaignItem.id}
              onChange={handleSelect}
              value={campaignItem.id}
              color="primary"
            />
          }
          style={{ marginInline: "1px 8px" }}
        />
  
        <div className="eachItem">
          <span className="title">{campaignItem?.title}</span>
  
          <div className="description">
            <div className="campaignType">
              {campaignItem.type === 1 ? "Full Featured" : "Email"}
            </div>
  
            <div className="campaignDate">
              <span style={{ display: "flex" }}>{Icons.calendar}</span>
              <span>{renderDate()}</span>
            </div>
          </div>
          
        <Collapse in={!isStatusCollapsed}>
            <div className="status">
                {renderStats()}
            </div>
        </Collapse>
 
          
        </div>
      </div>
    );
  };

export default EachCampaignItem;
import { makeStyles } from '@material-ui/core';
import React from 'react';


  const useStyles = makeStyles((theme) => ({
    actionContainer: {
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        padding: "0 16px",
        // padding: "20px 15px",
      },
      button: {
        padding: "8px 30px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
      },
      closeButton: {
        backgroundColor: "#ffffff",
        border: "2px solid #d9dce0",
        color: "#333",
        fontSize: "16px",
        "&:focus, &:hover": {
          backgroundColor: "#f6f6f6",
        },
      },
      confirmButton: {
        backgroundColor: "#3e50f7",
        color: "#fff",
        border: "2px solid #3e50f7",
        "&:focus, &:hover": {
          border: "2px solid #3e50f7",
          backgroundColor: "#3e50f7",
          transform: "translateY(-1px)",
        },
      },
      disableButton: {
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
      },
      addButtonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:4,
    
        backgroundColor: "transparent",
        color: "#3e50f7",
        border: "1px solid #3e50f7",
        '& svg': {
          color: "#3e50f7 !important",
        },
        "&:focus, &:hover": {
          backgroundColor: "transparent",
          transform: "translateY(-1px)",
        },
      },
  }));

const DrawerFooter = ({ selectedCampaign, onClose, isListAdding, onSubmit }) => {
    const classes = useStyles();
    return (
        <div className={classes.actionContainer}>
            <button
                onClick={onClose}
                className={`${classes.button} ${classes.closeButton}`}
                style={{ padding: "8px 24px" }}
            >
                Cancel
            </button>
            <button onClick={onSubmit}
                className={`${classes.button} ${!selectedCampaign ? classes.disableButton : classes.confirmButton}`}
                disabled={!selectedCampaign}>
                {isListAdding ? 'Save' : 'Select'}
            </button>
        </div>
    );
};

export default DrawerFooter;
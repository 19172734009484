import { makeStyles, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { PRIVACY_LEVEL } from "../../../helpers/CoreConstant";

export const globalPrivacyTabStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: "16px",
    "& .MuiTabs-flexContainer": {
        gap: 16
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "var(--light_blue)",
    },
  },
  tab: {
    fontSize: "15px !important",
    color: "var(--dark_blue)",
    fontWeight: 500,
    textTransform: "capitalize",
    "&:focus": {
      color: "var(--dark_blue)",
      backgroundColor: "transparent",
    },
    "&:hover": {
      color: "var(--light_blue)",
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      color: "var(--light_blue)",
      backgroundColor: "transparent",
    },
  },
}));

// DUMMY HANDLER
// const handleActiveTab = (_event, newValue) => {
//     setActiveTab(newValue);
//   };

const GlobalPrivacyTab = ({ activeTab, handleTabChange }) => {
  const classes = globalPrivacyTabStyles();
  return (
    <Tabs
      className={classes.tabs}
      value={activeTab}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab
        className={classes.tab}
        disableRipple
        label="Global Quick Replies"
        style={{ textTransform: "capitalize" }}
        value={PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL}
      />
      <Tab
        className={classes.tab}
        disableRipple
        label="My Quick Replies"
        style={{ textTransform: "capitalize" }}
        value={PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE}
      />
    </Tabs>
  );
};

export default GlobalPrivacyTab;

import { makeStyles } from "@material-ui/core";

export const customModalStyle = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "flex-end", // Align modal to the right
    alignItems: "flex-start", // Align to the top
    height: "100%", // Ensure the modal takes full height
  },
  paper: {
    backgroundColor: "#fff", // Set white background
    padding: theme.spacing(2, 2.5, 2),
    width: "700px", // Optional: Set the width of the modal to control the size
    boxShadow: theme.shadows[5], // Optional: Add shadow for better UI
    height: "100%", // Ensure the modal takes full height
    overflowY: "auto", // Add scroll if content overflows
    position: "fixed", // Add this line
    right: 0, // Add this line to ensure it sticks to the right
    top: 0, // Add this line to ensure it sticks to the top
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 0px",
    borderBottom: "1px solid #ddd",
  },
  headerText: {
    display: "flex",
    gap: "8px",
    justifyContent: "start",
    alignItems: "center",
  },
  modalWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    padding: "5px",
    marginTop: "35px",
  },
  button: {
    padding: "8px 16px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    minWidth: "93px",
  },
  closeButton: {
    backgroundColor: "#fff",
    color: "#000",
    border: "1px solid #a4a4a4",
    "&:focus, &:hover": {
      backgroundColor: "#f3f4f6",
      transform: "translateY(-1px)",
      border: "1px solid #a4a4a4",
    },
  },
  confirmButton: {
    backgroundColor: "#3e50f7",
    color: "#ffffff",
    border: "2px solid #3e50f7",
    "&:focus, &:hover": {
      backgroundColor: "#3e50f7",
      transform: "translateY(-1px)",
      border: "2px solid #3e50f7",
    },

    '&.addNewUserButton': {
      display: 'flex',
      alignItems: 'center',
      gap: '5px',

      '& .MuiSvgIcon-root': {
        fontSize: '18px',
        color: '#fff'
      }
    }
  },
}));
import React from "react";
import EachCampaignItem from "./EachCampaignItem";
import { CustomScrollbar } from "../assignToCampaignStyles";
import { TablePagination } from "@material-ui/core";
import CampaignListSkeleton from "./CampaignListSkeleton";
import EmptyStateMessageWithPicture from "../../../GlobalContactDetailsForDeal/LeftSide/common/EmptyStateMessageWithPicture";


const CampaignList = ({
  campaignList,
  isCampaignLoading,
  totalCampaignCount,
  selectedCampaign,
  setSelectedCampaign,
  isStatusCollapsed,
  page,
  setPage,
  perPage,
  setPerPage
}) => {
  const renderContent = () => {
    if (isCampaignLoading) {
      return <CampaignListSkeleton isStatusCollapsed={isStatusCollapsed} />;
    } else if (!isCampaignLoading && campaignList.length === 0) {
      return <EmptyStateMessageWithPicture message="Looks like the list is empty" />;
    } else if(!isCampaignLoading && campaignList[0]) {
      return campaignList?.map((item) => (
        <EachCampaignItem
          key={item.id}
          item={item}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          isStatusCollapsed={isStatusCollapsed}
        />
      ));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <CustomScrollbar style={{ maxHeight: "calc(100vh - 320px)" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {renderContent()}
      </div>

      {
        !isCampaignLoading && campaignList[0] && (
          <TablePagination
            component="div"
            count={totalCampaignCount || 0}
            page={page}
            rowsPerPage={perPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )
      }

    </CustomScrollbar>
  );
};

export default CampaignList;

import {Chip, InputAdornment, makeStyles} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import { getUserTagList, setUserTagList } from "../../../../actions/contactAction"
import { addTagsV2, getUserTagListNew } from "../../../../api/contactApi"
import {icons} from "./icon";
import "./modal_assign_to_tag.css";
import TagSkeleton from "./TagSkeleton";
import BasicSelect from "../../../custom/BasicSelect";
import Utils from "../../../../helpers/Utils";
import {BasicInput} from "../../components/RightSideV2Styles";
import useDebounce from "../../common/useDebounce";
import {AGENCY_BUSINESS_TYPE, PRIVACY_LEVEL} from "../../../../helpers/CoreConstant";
import CustomModal from "./customDynamicModal/CustomModal";

const getTextColor = (bgColor) => {
  const hexToRgb = (hex) => {
    if (hex){
      let r = parseInt(hex.substring(1, 3), 16);
      let g = parseInt(hex.substring(3, 5), 16);
      let b = parseInt(hex.substring(5, 7), 16);
      return { r, g, b };
    }

    return { r: 0, g: 0, b: 0 };
  };

  const getLuminance = ({ r, g, b }) => {
    const a = [r, g, b].map((v) => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  const luminance = getLuminance(hexToRgb(bgColor));

  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

const useStyles = makeStyles(() => ({
  chipStyle: {
    width: (props) => props.privacy_level === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? "88px" : "fit-content",
    height: "26px",
    fontWeight: 500,
    color: "#FFFFFF",
    backgroundColor: (props) => props.privacy_level === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? "#00ff91" : "#3e50f7",
    "& .MuiChip-label": {
      color: (props) => props.privacy_level === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? "#000000" : "#ffffff",
    },
  },
  textStyle: {
    color: (props) => getTextColor(props.color),
    backgroundColor: (props) => props.color || "#346fef",
    marginLeft: 8,
    width: "fit-content",
    fontWeight: "600",
    padding: "0px 6px",
    borderRadius: "6px"
  },
  emptyScreen: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10%",
    flexDirection: "column",
    alignItems: "center",
    "& span": {
      fontSize: "24px"
    }
  },
  eachItem: {
    '& .custom-checkbox-wrapper': {
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center'
    },
    '& .custom-checkbox-icon': {
      height: '20px'
    },
    '& .custom-icon-text-wrapper': {
      display: "flex",
      alignItems: 'center'
    }
  }
}));

const privacyLevelLabel = {
  [PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL]: 'Global Tags',
  [PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE]: 'My Tags'
};

const EachTag = ({ data, selectedTags, selectTag }) => {
  const classes = useStyles({ color: data.color, privacy_level: data.privacy_level });
  const checkSelect = (id) => {
    return selectedTags.includes(id);
  };

  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;

  return (
      <div className={classes.eachItem} style={{ fontSize: "16px", padding: "8px", margin: "8px 0px", background: "#fff" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div className='custom-checkbox-wrapper'>
            <div className='custom-icon-text-wrapper'>
              <div className='custom-checkbox-icon' onClick={() => selectTag(data.id)}>
                {checkSelect(data.id) ? icons.check : icons.unCheck}
              </div>
              <span className={classes.textStyle}>{data.name}</span>
            </div>
          </div>
          {isAgencyTypeInsurance && data.privacy_level && (
              <Chip label={privacyLevelLabel[data.privacy_level]} className={classes.chipStyle} />
          )}
        </div>
      </div>
  );
};

const ModalAssignToTagNewV2 = (props) => {
  const classes = useStyles();
  const [tagInfo, setTagInfo] = useState({
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "",
    allTags: [],
    selectedTags: [],
    newTagName: "",
    saveButtonText: "Assign Tag",
    createNew: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const [selectedPrivacyLevel, setSelectedPrivacyLevel] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    getTags();
  }, [currentPage, selectedPrivacyLevel, debouncedSearch, props.contactTags]);

  useEffect(()=>{
    if (props.getTagList){
      props.getTagList();
    }
  },[]);

  const getTags = async () => {
    try{
      const response = await getUserTagListNew({
        per_page: perPage,
        current_page: currentPage,
        search: debouncedSearch,
        privacyLevel: selectedPrivacyLevel,
      });

      if (response?.data) {
        let data = response.data;
        if (props.contactTags){
          const contactTagIds = new Set(props.contactTags.map(tag => tag.tag_id));
          console.log(contactTagIds);
          data = data.filter(tag => !contactTagIds.has(tag.id));
        }

        if (data.length === perPage){
          setHasMore(true);
        }else {
          setHasMore(false);
        }

        if (loadingMore){
          setList((prevList) => ([...prevList, ...data]));
        }else {
          setList(data);
        }
      }

    }catch (err){
      console.log(err);
    }finally {
      setIsLoading(false);
      setLoadingMore(false);
    }
  };

  const handleOnScrollLoad = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 50;
    if(bottom && !isLoading && hasMore){
      setLoadingMore(true);
      if (!loadingMore){
        setTimeout(()=>{
          let uPage = currentPage + 1;
          setCurrentPage(uPage);
        },500);
      }
    }
  };

  const handleSubmit = () => {
    if (tagInfo.submittingContact || (tagInfo.selectedTags.length === 0 && !tagInfo.createNew)) {
      window.showNotification("WARNING", "Please select a tag", 5000);
      return;
    }

    setTagInfo((prevTagInfo) => ({...prevTagInfo, submittingContact: true}));

    addTagsV2({contact_id: props.contact_id, tags: tagInfo.selectedTags, new: 0, assign: true})
        .then((res) => {
          if (res.data.status === "error") {
            setTagInfo((prevTagInfo) => ({
              ...prevTagInfo,
              error: true,
              errorMsg: res.data.html,
              submittingContact: false,
            }));
            window.showNotification("ERROR", `An error occurred: ${res.data.html}`);
          } else if (res.data.status === "success") {
            window.showNotification("SUCCESS", "Tag assigned successfully!");
            props.callBack(true, true, res.data.data);
          } else {
            setTagInfo((prevTagInfo) => ({...prevTagInfo, submittingContact: false}));
          }
        })
        .catch(() => {
          window.showNotification("ERROR", "An unexpected error occurred!");
          setTagInfo((prevTagInfo) => ({...prevTagInfo, submittingContact: false}));
        });
  };

  const handleSelectCheckbox = (value) => {
    setTagInfo((prevTagInfo) => {
      const selectedTags = prevTagInfo.selectedTags.includes(value)
          ? prevTagInfo.selectedTags.filter((id) => id !== value)
          : [...prevTagInfo.selectedTags, value];

      return { ...prevTagInfo, selectedTags };
    });
  };

  const runningList = () => {
    if (!list[0]){
      return (
          <div className={classes.emptyScreen}>
            <img src={`${process.env.REACT_APP_CDN_LINK}assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png`} alt="Not Found"/>
            <span>No Tags Found!</span>
          </div>
      );
    }
    return list.map((data) => (
        <EachTag
            key={data.id}
            data={data}
            selectedTags={tagInfo.selectedTags}
            selectTag={handleSelectCheckbox}
        />
    ));
  };

  const options = [
    { id: PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL, title: "Global Tags" },
    { id: PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE, title: "My Tags" }
  ];

  const handleSelectChange = (event) => {
    setSelectedPrivacyLevel(event.target.value);
    if (currentPage !== 1){
      setCurrentPage(1);
    }
  };

  const handleOnCreateTag = (newTagData) => {
    let tempList = [...list];
    tempList.unshift({
      id: newTagData.id,
      name: newTagData.title,
      privacy_level: newTagData.privacyLevel,
      color: newTagData.color,
    });
    setList(tempList);
    handleSelectCheckbox(newTagData.id);
  };

  return (
      <CustomModal
          {...props}
          open={props.open}
          onClose={props.onClose}
          title={"Assign Tag"}
          buttonText={tagInfo.submittingContact ? "Assigning..." : tagInfo.saveButtonText}
          onSubmit={handleSubmit}
          selectedPrivacyLevel={selectedPrivacyLevel}
          onCreateTag={handleOnCreateTag}
      >
        <div className='row'>
          <div className='col-md-12'>
            <form onSubmit={handleSubmit}>
              <div style={{ paddingBottom: "5px",paddingTop: "5px" ,display:"flex",justifyContent:"space-between",gap:"10px"}}>
                <BasicInput
                    onChange={(e) => setSearch(e.target.value.toUpperCase().trim())}
                    placeholder='Search for tags'
                    fullWidth
                    endAdornment={
                      <InputAdornment position='end'>
                        <SearchIcon style={{ color: "#656565", marginRight: "13px" }} />
                      </InputAdornment>
                    }
                />
                <div style={{width:"300px"}}>
                  <BasicSelect
                      emptyable={true}
                      fullWidth
                      name='tag'
                      defaultText='All Tags'
                      value={selectedPrivacyLevel}
                      options={options}
                      onChange={handleSelectChange}
                      mapping={{
                        label: "title",
                        value: "id",
                      }}
                  />
                </div>
              </div>
            </form>

            {(!loadingMore && isLoading) || tagInfo.submittingContact ? (
                <TagSkeleton />
            ) : (
                <div className='campaign-search-list'>
                  <div className='campaign-search-results'>
                    <div
                        className='all__tags__to__show__area2 m-nav awesome__scroll_bar update__list_item'
                        onScroll={handleOnScrollLoad}
                    >
                      <div>{runningList()}</div>
                      {loadingMore &&
                          <div>Loading...</div>
                      }
                    </div>
                  </div>
                </div>
            )}
          </div>
        </div>
      </CustomModal>
  );
};

const mapStateToProps = (state) => ({
  userTagList: state.rightsideReducer.userTagList,
  subUserList: state.rightsideReducer.subUserList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
  storeUserTagList: (params, callback) => dispatch(setUserTagList(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAssignToTagNewV2);

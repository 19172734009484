import React from 'react';
import { assignToCampaignStyles } from './assignToCampaignStyles';

const DrawerHeader = ({ onClose }) => {
    const title = 'Add To A Campaign';
    const description = 'Automated personalized drip marketing campaign.'
    const imgSrc = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/broadcast_5076027.png`;

    const classes = assignToCampaignStyles();
    return (
        <div className={classes.header} style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {imgSrc && (
                    <div className={classes.logoContainer}>
                        <img src={imgSrc} alt='logo' className={classes.logo} />
                    </div>
                )}
                
                <div className={classes.headerTxt}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.description}>{description}</div>
                </div>
            </div>

            {/* <button onClick={onClose} className={classes.headerClose}>
                <CloseIcon />
            </button> */}
        </div>
    );
};

export default DrawerHeader;
import { useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { getAgencyCategoriesApi, getAgencyTemplatesApi, getUserCategoriesApi, getUserTemplatesApi } from '../../../api/ApiAction';
import CustomCircleLoader from '../../../CustomCircleLoader';
import DirectMailIcons from '../../../DirectMailIcons';
import PostcardView from './type-wise-view/PostcardView';
import LetterView from './type-wise-view/LetterView';
import NotecardView from './type-wise-view/NotecardView';
import GreetingCardView from './type-wise-view/GreetingCardView';
import { useIsMounted } from '../../../../../hooks/useIsMounted';
import Utils from '../../../../../helpers/Utils';
import { AGENCY_BUSINESS_TYPE } from '../../../../../helpers/CoreConstant';

const TemplateViewContainer = ({selectDesignStep, handleDataChange, handleStepChange, type}) => {
    const isMounted = useIsMounted();
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
    const removedTitleList = [
        // post card
        "Roofing Postcards",
        "Roof Cleaning",
        "Window Washing",
        "Lawn Mowing Services",
        "Real Estate Investors",
        "Real Estate Postcards",
        "Landscaping",
        "Dryer Vent Cleaning",
        "Painting Contractor",
        "Carpet Cleaning",
        "Loan Officers",
        // note card 
        "New House To Neighbourhood",
        "Refer friend"
      ];
    useEffect(() => {
        if((!selectDesignStep.isLoadingCategory && selectDesignStep.categories.length === 0) || selectDesignStep.reloadCategory){
            handleDataChange({
                isLoadingCategory: true,
                reloadCategory: false,
            })
            getCategories(1)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDesignStep.templateFrom])

    useEffect(() => {
        if(isMounted){
            if(!selectDesignStep.isLoadingTemplate && selectDesignStep.selectedCategory != null && selectDesignStep.templates.length === 0){
                handleDataChange({
                    isLoadingTemplate: true
                })
                getTemplates(1)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectDesignStep.selectedCategory])

    const getCategories = (page = 1) => {
        if(selectDesignStep.templateFrom === "agency"){
            getAgencyCategories(page = 1)
        }
        else{
            getUserCategories()
        }
    }

    const getTemplates = (page = 1, categories = []) => {
        if(selectDesignStep.templateFrom === "agency"){
            getAgencyTemplates(page, categories)
        }
        else{
            getUserTemplates(page, categories)
        }
    }

    const getAgencyCategories = (page = 1) => {
        let type_value = (type === 'postcard' ? 6 : (type === 'letter' ? 9 : (type === 'zendirect_gift' ? 7 : 10)))
        getAgencyCategoriesApi({type: type_value}).then(res => {
            let response = res.data;
            if(response.status === "success"){
                const filteredCategoriesData = isAgencyTypeInsurance
                ? response.data.filter(item => !removedTitleList.includes(item.title))
                : response.data
                if(selectDesignStep.template_id_for_edit !== undefined && selectDesignStep.template_id_for_edit !== "" && selectDesignStep.template_id_for_edit != null){
                    // handleDataChange({categories: response.data, isLoadingCategory: false})
                    getTemplates(1, filteredCategoriesData)
                }
                else{
                    if(response.data.length > 0){
                        if(selectDesignStep.selectedCategory !== null){
                            handleDataChange({categories: filteredCategoriesData, isLoadingCategory: false})
                            getTemplates();
                        }
                        else{
                            handleDataChange({categories: filteredCategoriesData, isLoadingCategory: false, selectedCategory: filteredCategoriesData[0].id})
                        }
                    }
                    else{
                        handleDataChange({categories: filteredCategoriesData, isLoadingCategory: false})
                    }
                }
            }else{
                handleDataChange({categories: [], isLoadingCategory: false})
            }
        }).catch(error => {
            handleDataChange({categories: [], isLoadingCategory: false})
            console.log("SelectDesign > getAgencyCategories > getAgencyCategoriesApi", JSON.stringify(error))
        })
    }

    const getUserCategories = () => {
        let type_value = (type === 'postcard' ? 6 : (type === 'letter' ? 9 : (type === 'zendirect_gift' ? 7 : 10)))
        getUserCategoriesApi({type: type_value}).then(res => {
            let response = res.data;
            if(response.status === "success"){
                if(selectDesignStep.template_id_for_edit !== undefined && selectDesignStep.template_id_for_edit !== "" && selectDesignStep.template_id_for_edit != null){
                    getTemplates(1, response.data)
                }
                else{
                    if(response.data.length > 0){
                        if(selectDesignStep.selectedCategory != null){
                            handleDataChange({categories: response.data, reloadCategory:false})
                            getTemplates(1, response.data);
                        }
                        else{
                            handleDataChange({categories: response.data, isLoadingCategory: false, selectedCategory: response.data[0].id, reloadCategory: false})
                        }
                    }
                    else{
                        handleDataChange({categories: response.data, isLoadingCategory: false, reloadCategory: false})
                    }
                }
            }else{
                handleDataChange({categories: [], isLoadingCategory: false, reloadCategory: false})
            }
        }).catch(error => {
            handleDataChange({categories: [], isLoadingCategory: false})
            console.log("SelectDesign > getAgencyCategories > getAgencyCategoriesApi", JSON.stringify(error))
        })
    }
    
    const getAgencyTemplates = (page = 1, categories = []) => {
        getAgencyTemplatesApi({
            category_id: selectDesignStep.selectedCategory
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                // handleDataChange({templates: response.data, isLoadingTemplate: false})
                if(selectDesignStep.template_id_for_edit !== undefined && selectDesignStep.template_id_for_edit !== "" && selectDesignStep.template_id_for_edit != null){
                    let selected_template = response.data.filter(item => item.id === selectDesignStep.template_id_for_edit)
                    handleDataChange({
                        templates: response.data, 
                        selectedTemplate: selected_template.length > 0 ? selected_template[0] : null, 
                        isLoadingTemplate: false, 
                        template_id_for_edit : null,
                        categories: categories, 
                        isLoadingCategory: false
                    })
                }
                else{
                    if(categories.length !== 0){
                        handleDataChange({templates: response.data, isLoadingTemplate: false, isLoadingCategory:false, categories: categories})
                    }
                    else{
                        handleDataChange({templates: response.data, isLoadingTemplate: false})
                    }
                }
            }else{
                handleDataChange({templates: [], isLoadingTemplate: false})
            }
        }).catch(error => {
            handleDataChange({templates: [], isLoadingTemplate: false})
            console.log("SelectDesign > getAgencyCategories > getAgencyCategoriesApi", JSON.stringify(error))
        })
    }

    const getUserTemplates = (page = 1, categories = []) => {
        getUserTemplatesApi({
            category_id: selectDesignStep.selectedCategory
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                if(selectDesignStep.template_id_for_edit !== undefined && selectDesignStep.template_id_for_edit !== "" && selectDesignStep.template_id_for_edit != null){
                    let selected_template = response.data.filter(item => item.id === selectDesignStep.template_id_for_edit)
                    handleDataChange({
                        templates: response.data, 
                        selectedTemplate: selected_template.length > 0 ? selected_template[0] : null, 
                        isLoadingTemplate: false, 
                        template_id_for_edit : null,
                        categories: categories, 
                        isLoadingCategory: false
                    })
                }
                else{
                    if(categories.length !== 0){
                        handleDataChange({templates: response.data, isLoadingTemplate: false, isLoadingCategory:false, categories: categories})
                    }
                    else{
                        handleDataChange({templates: response.data, isLoadingTemplate: false, isLoadingCategory:false})
                    }
                }
                // handleDataChange({templates: response.data, isLoadingTemplate: false})
            }else{
                handleDataChange({templates: [], isLoadingTemplate: false})
            }
        }).catch(error => {
            handleDataChange({templates: [], isLoadingTemplate: false})
            console.log("SelectDesign > getAgencyCategories > getAgencyCategoriesApi", JSON.stringify(error))
        })
    }

    const handleTemplateTypeChange = (value) => {
        handleDataChange({
            templateFrom: value,
            isLoadingTemplate: false,
            page: 1,
            categories: [],
            templates: [],
            hasMoreTemplate: false,
            loadingMoreTemplate: false,
            selectedCategory: null,
            selectedTemplate: null
        })
    }

    const handleCategoryChange = (event) => {
        if(selectDesignStep.isLoadingTemplate){
            return
        }
        handleDataChange({
            selectedCategory: event.target.value,
            page: 1,
            templates: [],
            hasMoreTemplate: false,
            loadingMoreTemplate: false,
            selectedTemplate: null
        })
    }

    const handleTemplateSelect = (item) => {
        handleDataChange({
            selectedTemplate: item
        })
    }

    const renderCategories = () => {
        let view = []
        selectDesignStep.categories.forEach((item, index) => {
            view.push(
                <MenuItem key={index} value={item.id} className={'dropdownhelper-menuitem-class'}>
                    {item.title}
                </MenuItem>
            );
        });
        return view;
    }

    const renderTemplates = () => {
        if(selectDesignStep.isLoadingTemplate){
            return(
                <div style={{width: '100%', height: '100%', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                    <CustomCircleLoader />
                </div>
            );
        }
        if(selectDesignStep.categories.length === 0){
            return (
                <div className='select_category_empty'>
                    No category found for template !
                </div>
            );
        }
        
        if(selectDesignStep.selectedCategory === null){
            return (
                <div className='select_category_empty'>
                    Please select a category !
                </div>
            );
        }

        if(selectDesignStep.templates.length === 0){
            return (
                <div className='select_category_empty'>
                    No template found for this category !
                </div>
            );
        }

        let view = [];
        selectDesignStep.templates.forEach((item, index) => {
            let flag = (item === selectDesignStep.selectedTemplate || item.id === selectDesignStep.selectedTemplate?.id) ? true: false
            view.push(
                <div onClick={() => handleTemplateSelect(item)} className={`dmpc_tv_left_gallery_single ${flag ? 'dmpc_tv_left_gallery_single_active' : ''}`}>
                    <img src={item.image_thumb_path} alt={item.title} />
                </div>
            );
        });
        return (
            <div className='dmpc_tv_left_gallery awesome__scroll_bar'>
                {view}   
            </div>
        );
    }

    const renderRightSideView = () => {
        if(type === 'postcard'){
            return(
                <PostcardView 
                    selectDesignStep={selectDesignStep} 
                    handleStepChange={handleStepChange} 
                />
            );
        }
        else if(type === 'letter'){
            return(
                <LetterView 
                    selectDesignStep={selectDesignStep} 
                    handleStepChange={handleStepChange} 
                />
            );
        }
        else if(type === 'notecard'){
            return(
                <NotecardView 
                    selectDesignStep={selectDesignStep} 
                    handleStepChange={handleStepChange} 
                />
            );
        }
        else if(type === 'zendirect_gift'){
            return(
                <GreetingCardView 
                    selectDesignStep={selectDesignStep} 
                    handleStepChange={handleStepChange} 
                />
            );
        }
    }
    
    return (
        <div className='dmpc_templete_view'>
            <div className='dmpc_tv_left'>
                <div className='dmpc_tv_left_top'>
                    <button className={`dmpc_tv_lt_btn ${selectDesignStep.templateFrom === 'agency' ? 'dmpc_tv_lt_btn_active' : ''}`} onClick={() => handleTemplateTypeChange('agency')}>Template</button>
                    <button className={`dmpc_tv_lt_btn ${selectDesignStep.templateFrom === 'user' ? 'dmpc_tv_lt_btn_active' : ''}`}  onClick={() => handleTemplateTypeChange('user')}>Your Design</button>
                </div>

                <div className='dmpc_tv_left_innter'>
                    {!selectDesignStep.isLoadingCategory &&
                    <>
                    <div className='dmpc_tv_left_select'>
                        <FormControl className="dmpc_tv_left_select_formControll">
                            <Select
                                value={selectDesignStep.selectedCategory} 
                                onChange={handleCategoryChange}
                                className={"dmpc_tv_left_sf"}
                                displayEmpty
                                inputProps={{ 'className': "className__update"}}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItem value={null} disabled className={'dropdownhelper-menuitem-class'}>
                                    Select a category
                                </MenuItem>
                                {renderCategories()}
                            </Select>
                        </FormControl>
                    </div>
                    {renderTemplates()}
                    </>
                    }
                    {selectDesignStep.isLoadingCategory &&
                        <div style={{width: '100%', height: '100%', display: 'flex', alignItems:'center', justifyContent: 'center'}}>
                            <CustomCircleLoader />
                        </div>
                    }
                </div>
            </div>
            {renderRightSideView()}
        </div>
    );
}
export default TemplateViewContainer;
import { Box,} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

const FeatureRequest = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.setActiveSidebar) {
      window.setActiveSidebar('feature-request');
    }

    
    const scriptId = 'canny-jssdk';
    const existingScript = document.getElementById(scriptId);
    const renderCanny = () => {
      if (window.Canny) {
        window.Canny('render', {
          boardToken: process.env.REACT_APP_CANNY_BOARD_TOKEN,
          basePath: '/feature-request',
          ssoToken: null,
          theme: 'light',
        });
        const checkCannyLoaded = setInterval(() => {
          const cannyElement = document.querySelector('[data-canny]');
          if (cannyElement && cannyElement.childNodes.length > 0) {
            setLoading(false);
            clearInterval(checkCannyLoaded);
          }
        }, 3000);
      }
    };

    if (!existingScript) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://canny.io/sdk.js';
      script.async = true;
      script.onload = renderCanny;
      document.body.appendChild(script);
    } else {
      renderCanny();
    }
  }, []);

  const SkeletonLyout = ()=>{
    return (
      <div style={{width:'100%', height:'100%', display:'flex',flexDirection:'column', gap:'16px'}}>
      <Skeleton variant='rect' width={'100%'} height={'70px'} style={{marginTop:'20px', borderRadius:'8px'}} />
       <Skeleton variant='rect' width={'100%'} height={'250px'} style={{borderRadius:'8px'}} />
       <Skeleton variant='rect' width={'100%'} height={'150px'} style={{ borderRadius:'8px'}}/>
    </div>
    )
  }
  return (
    <Box sx={{ position: 'relative', width: '100%', minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading && (
        SkeletonLyout()
      )}
      <div data-canny style={{ width: '100%', display: loading ? 'none' : 'block' }} />
    </Box>
  );
};

export default FeatureRequest;

import { makeStyles, Radio, styled } from "@material-ui/core";

  export const assignToCampaignStyles = makeStyles((theme) => ({
    dialogPaper: {
      minWidth: "850px",
      maxWidth: "80%",
    },
    tabContent: {
      paddingTop: theme.spacing(1),
      maxHeight: "500px",
      minHeight: "500px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f3f6ff",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      scrollbarWidth: "thin",
      scrollbarColor: "#b8b8b9 #f3f6ff",
      borderRadius: "5px",
    },
    tabRoot: {
      textTransform: "capitalize",
      fontSize: "1rem",
      "&:hover": {
        color: theme.palette.primary.dark,
        backgroundColor: "#f3f6ff",
      },
      "&:focus": {
        backgroundColor: "transparent",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "8px 16px",
      gap: "5px",
      borderBottom: "1px solid #ccc",
    },
    headerTxt: {
      display: "flex",
      padding: "5px 6px",
      flexDirection: "column",
    },
    logoContainer: {
      width: "70px",
      height: "70px",
    },
    logo: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
    },
    description: {
      fontSize: "16px",
    },
    divider: {
      borderTop: "1px solid #ccc",
      margin: "0",
    },
    actionContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      padding: "20px 15px",
    },
    button: {
      padding: "8px 30px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    },

    headerClose: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        display: 'grid',
        placeContent: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none !important',

        '&:hover, &:focus': {
            backgroundColor: 'rgba(237, 0, 57, 0.03)'
        },
        '& svg path': {
            color: 'red'
        }
    },


    closeButton: {
      backgroundColor: "#ffffff",
      border: "2px solid #d9dce0",
      color: "#333",
      fontSize: "16px",
      "&:focus, &:hover": {
        backgroundColor: "#f6f6f6",
      },
    },
    confirmButton: {
      backgroundColor: "#3e50f7",
      color: "#fff",
      border: "2px solid #3e50f7",
      "&:focus, &:hover": {
        border: "2px solid #3e50f7",
        backgroundColor: "#3e50f7",
        transform: "translateY(-1px)",
      },
    },
    disableButton: {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    addButtonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap:4,

      backgroundColor: "transparent",
      color: "#3e50f7",
      border: "1px solid #3e50f7",
      '& svg': {
        color: "#3e50f7 !important",
      },
      "&:focus, &:hover": {
        backgroundColor: "transparent",
        transform: "translateY(-1px)",
      },
    },
  }));

  export const CustomScrollbar = styled('div')({
    overflowY: "auto",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: "#706fd3",
      backgroundColor: "#346fef",
      borderRadius: "10px",
      // outline: '1px solid #8e44ad'
    },
  });

  export const tabsStyles = makeStyles((theme) => ({
    tabs: {
        '& .MuiTabs-indicator': {
      backgroundColor: 'var(--light_blue)', 
    },
    },
    tab: {
        fontSize:'15px !important',
        color: 'var(--dark_blue)',
        fontWeight:500,
        textTransform:'capitalize',
        '&:focus': {
          color: 'var(--dark_blue)',
          backgroundColor: 'transparent', 

        },
        '&:hover': {
            color: 'var(--light_blue)',
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
         color: 'var(--light_blue)',
          backgroundColor: 'transparent',
        },
      },
      filterDesign: {
        minWidth: '185px !important',
        justifyContent: 'space-between',
        gap:'12px !important'
      }
}));

export const CustomRadio = styled(Radio)(() => ({
  "& .MuiSvgIcon-root": {
    fill: "#0000008a",
    fontSize: "1.2rem !important",
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      fill: "#3f51b5",
    },
  },
}));


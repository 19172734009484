import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { tabsStyles } from '../assignToCampaignStyles';
import { PRIVACY_LEVEL } from '../../../../helpers/CoreConstant';

const CampaignPrivacyTabs = ({ activeTab, handleTabChange }) => {
    const classes = tabsStyles();

    return (
        <Tabs
        className={classes.tabs}
        value={activeTab}
        onChange={handleTabChange}
      >
        <Tab className={classes.tab} label="Global Campaigns" value={PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL} />
        <Tab className={classes.tab} label="My Campaigns" value={PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE} />
      </Tabs>
    );
};

export default CampaignPrivacyTabs;
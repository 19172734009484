import React, { useCallback, useEffect, useState } from 'react';
import { BasicInput } from '../../../GlobalAddDealModalV3/GlobalAddDealModalStyles';
import { InputAdornment, makeStyles } from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import BasicSelect from '../../../custom/BasicSelect';
import { FoldIcon, UnfoldIcon } from '../Icons';
import { CAMPAIGN_STATUS, PRIVACY_LEVEL } from '../../../../helpers/CoreConstant';
import { fetchTeamMembers } from '../helper/Api';
import { Skeleton } from '@material-ui/lab';

const FILTER_STATUS = [
    {
      value: CAMPAIGN_STATUS.ALL,
      label: "All",
    },
    {
      value: CAMPAIGN_STATUS.RUNNING,
      label: "Running",
    },
    {
      value: CAMPAIGN_STATUS.PAUSED,
      label: "Paused",
    },
  ];

const filterButtonStyle = {
  border: '1px solid #ddd',
  borderRadius: '4px',
  display: 'inline-flex',
  padding: '5.5px 4px',
  cursor: 'pointer',
};

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        marginBottom: "10px", 
        marginBlock: "10px",

        "& .filterByStatus": {
            maxWidth: 150
        }
    }
}));

const CampaignFilter = ({ activeTab, filters, setFilters, isStatusCollapsed, setIsStatusCollapsed }) => {
    const [searchValue, setSearchValue] = useState(filters.search || '');
    const [teamUserList, setTeamUserList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();
    
    useEffect(() => {

        const getTeamMembers = async () => {
            setIsLoading(true);
            try {
                const response = await fetchTeamMembers({});
                if(response.status === 200) {
                    setTeamUserList(response.data.users);
                }else{
                    setTeamUserList([]);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
        
        getTeamMembers();
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce((value) => {
            setFilters(prevFilters => ({ ...prevFilters, search: value || null }));
        }, 300),
        [setFilters]
    );


    
    // Sync local state with filters.search if it changes externally
    useEffect(() => {
        setSearchValue(filters.search || '');
    }, [filters.search]);

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchValue(value); // Update local state immediately
        debouncedSearch(value); // Debounced update to filters
    };

    const handleFilterStatus = (e) => {
        setFilters({ ...filters, status: e.target.value });
    };

    const handleFilterByUser = (e) => {
        setFilters({ ...filters, createdBy: e.target.value });
    };

    const renderTeamMembers = () => {
        if (isLoading) {
            return <Skeleton variant="rect" height="34px" width="330px" />;
        }else if (!isLoading || teamUserList[0]) {
            return (
                <BasicSelect
                    options={teamUserList}
                    defaultText="Filter By User"
                    mapping={{
                        value: "value",
                        label: "title",
                    }}
                    value={filters.createdBy || ""} // Fixed: use createdBy instead of status
                    onChange={handleFilterByUser}
                    style={{ width: "145px" }}
                    emptyable
                    className={"filterByTeam"}
                />
            )
        }
    }

    const isPrivacyGlobal = activeTab === PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL;

    return (
        <div className={classes.wrapper}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <span 
                    style={filterButtonStyle} 
                    onClick={() => setIsStatusCollapsed(!isStatusCollapsed)}
                >
                    {isStatusCollapsed ? <FoldIcon /> : <UnfoldIcon />}
                </span>
                

                <BasicInput
                    value={searchValue} // Use local state instead of filters.search
                    placeholder="Search"
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{ color: "#656565" }} />
                        </InputAdornment>
                    }
                    style={{ width: "230px" }}
                    onChange={handleSearch}
                />
            </div>
            
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px", width: isPrivacyGlobal ? 400 : 160 }}>
                { isPrivacyGlobal && renderTeamMembers() }
                

                <BasicSelect
                    options={FILTER_STATUS}
                    defaultText="Filter By Status"
                    mapping={{
                        value: "value",
                        label: "label",
                    }}
                    value={filters.status || ""}
                    onChange={handleFilterStatus}
                    style={{ width: "145px" }}
                    className={"filterByStatus"}
                />
            </div>
        </div>
    );
};

export default CampaignFilter;
import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles
} from "@material-ui/core";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { IconHandler } from "./IconHandler";


const useStyles = makeStyles({
  folderTitle: {
    width: "85%",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const EachFolderItem = ({
  folder,
  selectedFolder,
  onSelectFolder,
}) => {

  const handleSelectedFolder = () => {
    onSelectFolder(folder.id);
  };

  const iconHandler = new IconHandler(folder.text_color, parseInt(folder.icon))
  const { folderTitle } = useStyles();
  return (
    <>
      <BootstrapTooltip
        title={`${folder?.title.length > 26 ? folder.title : ""}`}
      >
        <ListItem
          button
          selected={selectedFolder === folder.id}
          onClick={handleSelectedFolder}
        >
          <ListItemIcon>
            {/* {renderFolderIcon(folder.folder_icon, folder.folder_color, 20)} */}
            {iconHandler.getIcon}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" className={folderTitle}>
              {folder.title}
            </Typography>
          </ListItemText>
        </ListItem>
      </BootstrapTooltip>

    </>
  );
};

export default EachFolderItem;

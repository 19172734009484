import {
  Box,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getContactTagList,
  getUserTagList,
} from "../../../../actions/contactAction";
import DynamicSkeleton from "../../../common/DynamicSkeleton";
import CustomTitle from "../common/CustomTitle";
import TagsList from "./TagsList";
import ModalAddTagFormNew from "./ModalAddTagFormNew";
import Utils from "../../../../helpers/Utils";
import {AGENCY_BUSINESS_TYPE} from "../../../../helpers/CoreConstant";
import ModalAssignToTagNewV2 from "./ModalAssignToTagNewV2";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const TagsForContact = (props) => {
  const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal((prevState) => !prevState);
  };

  const getTagList = () => {
    props.getContactTagList({
      contactId: props.contact.id,
    });
  };

  const successCallBack = (update = false, newTag = false) => {
    setOpenModal(false)
    getTagList()
    if (newTag) {
      props.fetchUserTagList({})
    }
  }

  const icons = useStyles();
  return (
    <Box className={icons.hoverDiv} sx={{ mt: 3 }}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"TAGS"}
          iconHide={icons.icon}
        />
      </Box>
      {props.tagLoading ? <DynamicSkeleton count={1} /> : <TagsList />}
      {
        openModal && (
              isAgencyTypeInsurance ?
                  <ModalAssignToTagNewV2
                      open={openModal}
                      onClose={()=>setOpenModal(false)}
                      contact_id={[props.contact.id]}
                      title={"Add Tag"}
                      contactTags={props.contactTags}
                      callBack={successCallBack}
                  /> :
                  <ModalAddTagFormNew
                      open={openModal}
                      onClose={()=>setOpenModal(false)}
                      contact_id={[props.contact.id]}
                      title={"Add Tag"}
                      callBack={successCallBack}
                  />
          )
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tagLoading: state.tagReducer.tagLoading,
    contact: state.addContactReducer.contact,
    contactTags: state.tagReducer.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactTagList: (params) => dispatch(getContactTagList(params)),
    fetchUserTagList: (params, callback) =>
      dispatch(getUserTagList(params, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TagsForContact);

import {
  Backdrop,
  Checkbox,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useState } from "react";
import Utils from "../../../helpers/Utils";
import BasicSelect from "../../custom/BasicSelect";
import {
  BasicFormLabel,
  BasicInput,
} from "../../GlobalAddDealModalV3/GlobalAddDealModalStyles";
import { PROCESS_MODE_VALUE } from "../../../helpers/CoreConstant";
import { addContactsInCampaign } from "./helper/Api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: 8,
    maxWidth: 885,
    width: "100%",

    "& .dateTimePicker": {
      border: "1px solid #ced4da",
    },
  },

  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 8,
    marginBottom: 16,
    gap: "5px",
    borderBottom: "1px solid #ccc",
  },
  headerTxt: {
    display: "flex",
    padding: "5px 6px",
    flexDirection: "column",
  },
  // logoContainer: {
  //   width: "400px",
  //   height: "400px",
  // },
  logo: {
    width: 50,
    height: 50,
    objectFit: "cover",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& button": {
      margin: theme.spacing(0.5),
    },
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: theme.spacing(0.25),
  },
  weekdaysGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
    "& .MuiFormControlLabel-root": {
      marginRight: theme.spacing(2),
    },
  },

  button: {
    padding: "8px 30px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  closeButton: {
    backgroundColor: "#ffffff",
    border: "2px solid #d9dce0",
    color: "#333",
    fontSize: "16px",
    "&:focus, &:hover": {
      backgroundColor: "#f6f6f6",
    },
  },
  confirmButton: {
    backgroundColor: "#3e50f7",
    color: "#fff",
    border: "2px solid #3e50f7",
    "&:focus, &:hover": {
      border: "2px solid #3e50f7",
      backgroundColor: "#3e50f7",
      transform: "translateY(-1px)",
    },
  },
  

  footer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
}));

const reformatDate = (inputDate) => {
  const [month, day, year] = inputDate?.split("-");
  return `${year}-${month}-${day}`;
};

const PROCESS_TYPE = {
  MINUTE: 1,
  HOUR: 2,
  DAYS: 3,
};

const QUANTITY_MINUTE_MAX = 20;
const QUANTITY_HOUR_MAX = 200;
const QUANTITY_DAY_MAX = 1000;

const imgSrc = `${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/broadcast_5076027.png`;

const SubmitModal = ({
  open,
  onClose,
  drawerPayload,
  onCloseDrawer,
  campaignName,
  callBack
}) => {
  let userTimeNow = window?.globalTimezoneConversionToDifferentTimezone
    ? window.globalTimezoneConversionToDifferentTimezone(new Date())
    : new Date(moment.tz(Utils.getAccountData("userTimezoneRaw")));

  const userTimeZone = Utils.getAccountData("userTimezone");
  const classes = useStyles();
  const [processMode, setProcessMode] = useState(PROCESS_MODE_VALUE.ONCE);
  const [selectedDate, setSelectedDate] = useState(
    reformatDate(userTimeNow?.split(" ")[0])
  );
  const [startTime, setStartTime] = useState(userTimeNow.split(" ")[1]);
  const [processType, setProcessType] = useState(PROCESS_TYPE.MINUTE);
  const [batchQuantity, setBatchQuantity] = useState(1);
  const [repeatAfter, setRepeatAfter] = useState(1);
  const [weekDays, setWeekDays] = useState({
    Sun: true,
    Mon: true,
    Tue: true,
    Wed: true,
    Thu: true,
    Fri: true,
    Sat: true,
  });

  const [maxQuantity, setMaxQuantity] = useState(QUANTITY_MINUTE_MAX);

  const handleProcessTypeChange = (e) => {
    let value = e.target.value;
    if (value === PROCESS_TYPE.HOUR) {
      if (repeatAfter > 23) {
        setRepeatAfter(23);
      }
      if (batchQuantity > QUANTITY_HOUR_MAX) {
        setBatchQuantity(QUANTITY_HOUR_MAX);
      }
      setMaxQuantity(QUANTITY_HOUR_MAX);
    } else if (value === PROCESS_TYPE.DAYS) {
      if (repeatAfter > 365) {
        setRepeatAfter(365);
      }
      if (batchQuantity > QUANTITY_DAY_MAX) {
        setBatchQuantity(QUANTITY_DAY_MAX);
      }
      setMaxQuantity(QUANTITY_DAY_MAX);
    } else {
      if (repeatAfter > 59) {
        setRepeatAfter(59);
      }
      if (batchQuantity > QUANTITY_MINUTE_MAX) {
        setBatchQuantity(QUANTITY_MINUTE_MAX);
      }
      setMaxQuantity(QUANTITY_MINUTE_MAX);
    }
    setProcessType(value);
  };

  const handleBatchQuantity = (e) => {
    let value = e.target.value;
    if (value.trim() === "") {
      setBatchQuantity("");
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        value = 1;
      }
      if (value > maxQuantity) {
        setBatchQuantity(maxQuantity);
      } else {
        setBatchQuantity(value);
      }
    }
  };

  const handleRepeatTimeChange = (e) => {
    let value = e.target.value;
    if (value.trim() === "") {
      setRepeatAfter("");
    } else {
      value = parseInt(value);
      if (isNaN(value)) {
        value = 1;
      }
      if (processType === PROCESS_TYPE.MINUTE) {
        if (value < 1) {
          setRepeatAfter(1);
        } else if (value > 59) {
          setRepeatAfter(59);
        } else {
          setRepeatAfter(value);
        }
      } else if (processType === PROCESS_TYPE.HOUR) {
        if (value < 1) {
          setRepeatAfter(1);
        } else if (value > 23) {
          setRepeatAfter(23);
        } else {
          setRepeatAfter(value);
        }
      } else if (processType === PROCESS_TYPE.DAYS) {
        if (value < 1) {
          setRepeatAfter(1);
        } else if (value > 365) {
          setRepeatAfter(365);
        } else {
          setRepeatAfter(value);
        }
      }
    }
  };

  const handleWeekDays = (event) => {
    let name = event.target.name;

    setWeekDays({
      ...weekDays,
      [name]: event.target.checked,
    });
  };
  const validateBatchForm = () => {
    if (isNaN(batchQuantity)) {
      Utils.showNotification("Must be a valid quantity", "ERROR");
      return false;
    } else if (batchQuantity <= 0) {
      Utils.showNotification("Batch Quantity must be greater than 0", "ERROR");
      return false;
    }

    // if (isNaN(repeatAfterDays)) {
    //     Utils.showNotification("Must be a valid repeat after days value","ERROR");
    //     return false;
    // } else if (repeatAfterDays <= 0 || repeatAfterDays > 100) {
    //     Utils.showNotification("Repeat after days must be between 1 to 100","ERROR");
    //     return false;
    // }

    if (!Object.values(weekDays).includes(true)) {
      Utils.showNotification("Please select minimum one send on day.", "ERROR");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateBatchForm()) {
      return;
    }

    try {
      const payload =
        processMode === PROCESS_MODE_VALUE.ONCE
          ? drawerPayload
          : {
              batchForm: {
                batch_process_type: processType,
                campaign_id: drawerPayload.campaignId,
                quantity: batchQuantity,
                repeat_after_days: repeatAfter,
                send_on: weekDays,
                start_from: selectedDate + " " + startTime,
              },
              contactType: "single-contact",
              ...drawerPayload,
            };

      const response = await addContactsInCampaign(payload);

      if (response.data.status === "success") {
        callBack();
        
        window.showNotification(
          "SUCCESS",
          response.data.html === "Success"
            ? "Contacts Move to Campaign Successfully"
            : response.data.html
        );
        onClose();
        onCloseDrawer();
      } else {
        window.showNotification("ERROR", "Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header} style={{fontFamily: "Roboto, Helvetica, Arial, sans-serif"}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                  {imgSrc && (
                      <div className={classes.logoContainer}>
                          <img src={imgSrc} alt='logo' className={classes.logo} />
                      </div>
                  )}
                  
                  <div className={classes.headerTxt}>
                      <div className={classes.title}>Campaign Process Mode</div>
                  </div>
              </div>
          </div>

          <div style={{ marginBottom: "16px" }}>
            <b>{drawerPayload?.contact_ids?.length}</b> contacts is going to add
            in campaign <b>{campaignName || ""}</b>
          </div>

          <div style={{ minHeight: "352px" }}>
            {/* Process Mode Selection */}
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                row
                value={processMode}
                onChange={(e) => setProcessMode(e.target.value)}
              >
                <FormControlLabel
                  value={PROCESS_MODE_VALUE.ONCE}
                  control={<Radio color="primary" size="small" />}
                  label="Process all at once"
                />
                <FormControlLabel
                  value={PROCESS_MODE_VALUE.DRIP}
                  control={<Radio color="primary" size="small" />}
                  label="Process in drip mode"
                />
              </RadioGroup>
            </FormControl>

            {/* Show the rest of the form only if "Process in drip mode" is selected */}
            {processMode === "drip" && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BasicFormLabel variant="body1">
                      Start On <span style={{ color: "red" }}>*</span>
                    </BasicFormLabel>
                  </Grid>

                  {/* Date and Time */}
                  <Grid item xs={4}>
                    <BasicFormLabel variant="body1">Date</BasicFormLabel>

                    <BasicInput
                      type="date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BasicFormLabel variant="body1">Time</BasicFormLabel>

                    <BasicInput
                      type="time"
                      fullWidth
                      value={startTime}
                      name="startTime"
                      onChange={(e) => setStartTime(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <BasicFormLabel
                      variant="body1"
                    >
                      Campaign Timezone
                    </BasicFormLabel>

                    <BasicInput disabled fullWidth value={userTimeZone} />
                  </Grid>

                  {/* Process Type */}

                  <Grid item xs={4}>
                    <BasicFormLabel variant="body1">
                      Process Type
                    </BasicFormLabel>

                    <BasicSelect
                      fullWidth
                      name="processType"
                      defaultText="Select an option"
                      options={[
                        {
                          id: PROCESS_TYPE.MINUTE,
                          title: "Minute(s)",
                        },
                        {
                          id: PROCESS_TYPE.HOUR,
                          title: "Hour(s)",
                        },
                        {
                          id: PROCESS_TYPE.DAYS,
                          title: "Day(s)",
                        },
                      ]}
                      mapping={{ label: "title", value: "id" }}
                      value={processType}
                      onChange={handleProcessTypeChange}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <BasicFormLabel variant="body1">
                      Batch Quantity <span style={{ color: "red" }}>*</span>
                    </BasicFormLabel>

                    <BasicInput
                      type="number"
                      fullWidth
                      name="batchQuantity"
                      value={batchQuantity}
                      onChange={handleBatchQuantity}
                    />

                    <span
                      variant="body1"
                      style={{ fontSize: "12px", fontWeight: 300 }}
                    >
                      Max quantity limit: {maxQuantity}
                    </span>
                  </Grid>

                  <Grid item xs={4}>
                    <BasicFormLabel variant="body1">
                      Repeat After (
                      {processType === PROCESS_TYPE.MINUTE
                        ? "Minutes"
                        : processType === PROCESS_TYPE.HOUR
                        ? "Hours"
                        : "Days"}
                      )<span style={{ color: "red" }}>*</span>
                    </BasicFormLabel>

                    <BasicInput
                      type="number"
                      fullWidth
                      value={repeatAfter}
                      onChange={handleRepeatTimeChange}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div
                    className={classes.fieldLabel}
                    style={{ marginTop: "16px" }}
                  >
                    Process contacts on the following days:
                  </div>
                  <FormControl
                    className={classes.weekdaysGroup}
                    style={{ flexDirection: "row" }}
                    onChange={handleWeekDays}
                  >
                    <FormControlLabel
                      value="Sun"
                      checked={weekDays.Sun}
                      control={<Checkbox size="small" color="primary" />}
                      label="Sunday"
                      name="Sun"
                    />
                    <FormControlLabel
                      value="Mon"
                      checked={weekDays.Mon}
                      control={<Checkbox size="small" color="primary" />}
                      label="Monday"
                      name="Mon"
                    />
                    <FormControlLabel
                      value="Tue"
                      checked={weekDays.Tue}
                      control={<Checkbox size="small" color="primary" />}
                      label="Tuesday"
                      name="Tue"
                    />
                    <FormControlLabel
                      value="Wed"
                      checked={weekDays.Wed}
                      control={<Checkbox size="small" color="primary" />}
                      label="Wednesday"
                      name="Wed"
                    />
                    <FormControlLabel
                      value="Thu"
                      checked={weekDays.Thu}
                      control={<Checkbox size="small" color="primary" />}
                      label="Thursday"
                      name="Thu"
                    />
                    <FormControlLabel
                      value="Fri"
                      checked={weekDays.Fri}
                      control={<Checkbox size="small" color="primary" />}
                      label="Friday"
                      name="Fri"
                    />
                    <FormControlLabel
                      value="Sat"
                      checked={weekDays.Sat}
                      control={<Checkbox size="small" color="primary" />}
                      label="Saturday"
                      name="Sat"
                    />
                  </FormControl>
                </Grid>
              </>
            )}
          </div>

          {/* Footer with Cancel and Submit Buttons */}
          <Divider style={{ marginBlock: 16 }} />
          <div className={classes.footer}>
              <button
                onClick={onClose}
                className={`${classes.button} ${classes.closeButton}`}
                style={{ padding: "8px 24px" }}
              >
                Back
              </button>
              <button
                onClick={handleSubmit}
                className={`${classes.button} ${classes.confirmButton}`}
              >
                 Start
              </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SubmitModal;

import { Avatar, IconButton, styled } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { deleteCookie } from "../../../helpers/Cookie";
import Utils from "../../../helpers/Utils";
import PermissionConst from "../../../constants/PermissionConst";
import { AGENCY_BUSINESS_TYPE } from "../../../helpers/CoreConstant";

const ProfilePopoverMenuInsuranceWrapper = styled("div")(({ theme }) => ({
    backgroundColor: "var(--white)",
    borderRadius: "10px",
    boxShadow: "0 0 17px 1px #bbb",
    right: "-5px",
    overflow: "visible",
    position: "absolute",
    top: "60px",
    width: "270px",
    zIndex: "999",

    "&:before": {
        background: "url(/static/media/arrow.20587642.svg) no-repeat 50% / 34px 24px",
        backgroundSize: "cover",
        content: "''",
        display: "block",
        height: "23px",
        position: "absolute",
        right: "42%",
        top: "-12px",
        width: "34px",
        zIndex: "-1"
    },

    "& .menuUl": {
        "& .menuLi": {
            borderBottom: "1px solid var(--gray_dark)",
            padding: "14px 16px",
            userSelect: "none",
            cursor: "pointer",

            "& h4": {
                color: "#133159",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                margin: "0",
            },

            "& span": {
                color: "var(--gray)",
                fontSize: "14px",
                lineHeight: "22px",
                paddingBottom: "9px"
            },
            "&.profileMenuLi": {
                alignItems: "center",
                display: "flex",
                gap: "10px",
                padding: "20px",
                wordBreak: "break-word"
            },
            "& .profileWrapper": {
                display: "flex",
                alignItems: "center",
                gap: 8,
            },

            "& .avatarImgWrapper": {
                borderRadius: "50%",
                border: "1px solid #ddd",
                display: "flex",
                "& .avatarImg": {
                    width: 45,
                    height: 45,
                    objectFit: "cover",
                    borderRadius: "50%",
                },

                "& .MuiAvatar-colorDefault": {
                    backgroundColor: "#bdbdbd"
                }
            },

            "& .userInfo": {
                "& .title": {
                    color: "#000000",
                    fontSize: 16,
                    fontWeight: 600,
                    lineHeight: "20px",

                    textAlign: "left",
                    margin: 0,
                },
                "& .email": {
                    color: "#9EA2AD",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "16px",

                    textAlign: "left",
                    margin: 0,
                },
            },
        }
    }
}));

const appendToArrayAtIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index)];
};

const TopbarProfilePopover = ({ avatar, userEmail, userName }) => {
    const history = useHistory();
    const [openSearchMenu, setOpenSearchMenu] = useState(false);
    const [menuOptions, setMenuOptions] = useState([
        {
            menu: "my-profile",
            label: "My Profile",
            description: "Update your personal and company profile, change your password, view billing info and more"
        },
        {
            menu: "logout",
            label: "Logout",
            description: "",
            color: "var(--red)"
        }
    ]);
    const isAgencyTypeInsurance = Utils.getAccountData("agencyBusinessType") === AGENCY_BUSINESS_TYPE.INSURANCE;

    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (window.hasPermission) {
            if(window.hasPermission(PermissionConst.MODULES.MANAGE_USER,
                PermissionConst.PERMISSIONS.READ)){
                    setMenuOptions((prevState) =>
                        appendToArrayAtIndex(prevState, 1, {
                        menu: "my-team",
                        label: "My Team",
                        description: "Add, delete and manage your users"
                    }));
            }
            if(window.hasPermission(PermissionConst.MODULES.BILLING,
                PermissionConst.PERMISSIONS.READ)){
                    setMenuOptions((prevState) =>
                        appendToArrayAtIndex(prevState, 2, {
                        menu: "package",
                        label: "Package",
                        description: "Upgrade or downgrade your existing plan"
                    }));
            }
            if(window.hasPermission(PermissionConst.MODULES.BILLING,
                PermissionConst.PERMISSIONS.READ)){
                    setMenuOptions((prevState) =>
                        appendToArrayAtIndex(prevState, 3, {
                        menu: "billing",
                        label: "Billing",
                        description: "Your transaction history"
                    }));
            }
        }
    }, []);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target)
            && popoverRef.current && !popoverRef.current.contains(event.target)) {
            setOpenSearchMenu(false);
        }
    };

    const handleClick = (item) => {
      if (item.menu === "my-profile"){
          history.push("/user/profile");
      }else if (item.menu === "my-team"){
        const path = isAgencyTypeInsurance ? "/user/profile/permission-settings" : "/user/profile/team";
        history.push(path);
      }else if (item.menu === "package"){
          history.push("/user/packages");
      }else if (item.menu === "billing"){
          history.push("/billing");
      }else if (item.menu === "logout"){
          let userId = Utils.getAccountData("userId");
          deleteCookie(process.env.REACT_APP_ACCESS_TOKEN);
          deleteCookie(process.env.REACT_APP_ACCOUNT_DATA);
          if (localStorage.getItem("global__custom__field__data")) {
              localStorage.removeItem("global__custom__field__data");
          }

          const channel = new BroadcastChannel("pypepro-channel");
          channel.postMessage({ "action": "reload-page-" + userId, "link": "/" });
      }

      setOpenSearchMenu(false);
    };

    return(
        <div style={{ position: "relative" }}>
            <IconButton buttonRef={buttonRef} className="actionButton profile" onClick={()=>{ setOpenSearchMenu((prevState)=>(!prevState)); }}>
                <div className="profileWrapper">
                    <div className="avatarImgWrapper">
                        {
                            avatar ?
                                <img
                                    className="avatarImg"
                                    src={avatar}
                                    alt=""
                                /> :
                                <Avatar>{Utils.getInitial(userName)}</Avatar>
                        }
                    </div>

                    <div className="userInfo">
                        <p className="title">{userName}</p>
                        <p className="email">{userEmail}</p>
                    </div>
                </div>
            </IconButton>

            <ProfilePopoverMenuInsuranceWrapper ref={popoverRef} style={{ visibility: openSearchMenu ? "visible" : "hidden" }}>
                <div className="menuUl">
                    {
                        <div className="menuLi profileMenuLi">
                            <div className="avatarImgWrapper">
                                {
                                    avatar ?
                                        <img
                                            className="avatarImg"
                                            src={avatar}
                                            alt=""
                                        /> :
                                        <Avatar>{Utils.getInitial(userName)}</Avatar>
                                }
                            </div>

                            <div className="userInfo">
                                <p className="title">{userName}</p>
                                <p className="email">{userEmail}</p>
                            </div>
                        </div>
                    }
                    {
                        menuOptions.map((item) => (
                            <div className="menuLi" onClick={() => {
                                handleClick(item);
                            }} key={item.menu}>
                                <h4 style={{ color: item.color }}>{item.label}</h4>
                                {
                                    item.description &&
                                    <span>{item.description}</span>
                                }
                            </div>
                        ))
                    }
                </div>
            </ProfilePopoverMenuInsuranceWrapper>
        </div>
    );
};

export default TopbarProfilePopover;
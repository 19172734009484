import {useContext, useEffect, useState} from 'react';
import ApiList from '../assets/ApiList';
import LinkFile from '../LinkFile';
import { updateIsShowQuickReplyFlag, updateMessage, updateSubject } from '../reducers/Action';
import { EmailSendContext } from '../reducers/GlobalReduers';
import QuickReplySkeleton from './QuickReplySkeleton';
import GlobalPrivacyTab from '../../globalPrivacyTab';
import { PRIVACY_LEVEL } from '../../../../helpers/CoreConstant';
import EmptyStateMessageWithPicture from '../../../GlobalContactDetailsForDeal/LeftSide/common/EmptyStateMessageWithPicture';

const QuickRepliesForEmailTemplates = (props) => {

    const perPage = 20;

    const {states, dispatch} = useContext(EmailSendContext)
    const [isLoading, setIsLoading] = useState(true)
    const [templates, setTemplates] = useState([])

    /* for fetching data onscroll */
    const [currentPage, setCurrentPage] = useState(0)

    const [activeTab, setActiveTab] = useState(PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL);

    useEffect(() => {
        // get all email template
        ApiList.getEmailTemplates({
            search_key: "",
            type: 1,
            'perPage': perPage,
            'page': currentPage,
            privacy_level: activeTab
        }).then(res => {
            let response = res.data
            if(response.status === 'success'){
                setTemplates(response.html)
                if(response.html.length >= perPage){
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", true)
                    }
                    setCurrentPage(currentPage + 1)
                }
                else{
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("needToLoad", false)
                    }
                }
                if(props.updateScrollOption !== undefined){
                    props.updateScrollOption("fetchingMore", false)
                }
            }
            setIsLoading(false)
        })
        return () => setIsLoading(true)
    }, [activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.scrolling){
            ApiList.getEmailTemplates({
                search_key: "",
                type: 1,
                'perPage': perPage,
                'page': currentPage,
                privacy_level: activeTab
            }).then(res => {
                let response = res.data
                if(response.status === 'success'){
                    if(response.html.length >= perPage){
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", true)
                        }
                        setCurrentPage(currentPage + 1)
                    }
                    else{
                        if(props.updateScrollOption !== undefined){
                            props.updateScrollOption("needToLoad", false)
                        }
                    }
                    if(props.updateScrollOption !== undefined){
                        props.updateScrollOption("fetchingMore", false)
                    }
                    setTemplates([...templates, ...response.html])
                }
            })
        }
    }, [props.scrolling]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSaveAs = () => {
        dispatch(updateIsShowQuickReplyFlag(!states.isOpenQuickReply))
    }

    const hanldeTemplateChange = (subject, message) => {
        dispatch(updateSubject({
            subject: subject,
            validSubject: false,
        }))
        dispatch(updateMessage({
            message: message,
            validMessage: false,
        }))
        handleSaveAs()
    }

    const hanldeTemplateSelect = (item) => {
        hanldeTemplateChange(item.subject, item.message)
    }

    const renderList = () => {
        let list = []
        templates.forEach((item, index) => {
          list.push(
            <LinkFile.QucikReplyCard
              data={item}
              key={index}
              onSelect={hanldeTemplateSelect}
            />
          );
          // list.push(
          //     <div className="g-template-each" key={index} onClick={() => hanldeTemplateChange(item.subject, item.message)}>
          //         <span className="g-template-each-title">{item.title}</span>
          //         <span className="g-template-each-subject">{item.subject}</span>
          //         <span className="g-template-each-message">{item.message}</span>
          //     </div>
          // )
        });
        return list;
    }

    const handleActiveTab = (_event, newValue) => {
        setActiveTab(newValue);
    };

    return(
        <div className="g-email-template-content">
            <div className="g-template-header">
                <span className="g-template-title">Email Quick Reply List</span>
                <div className="g-template-back" onClick={handleSaveAs}> {'<<'} Back</div>
            </div>
            
            <GlobalPrivacyTab activeTab={activeTab} handleTabChange={handleActiveTab} />

            <div>
                {isLoading ? 
                    <QuickReplySkeleton/>
                    :
                    renderList()
                }
            </div>

            {
                !isLoading && templates.length === 0 && (<EmptyStateMessageWithPicture />)
            }
        </div>
    )
}
export default QuickRepliesForEmailTemplates;



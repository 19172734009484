import React from "react";
import { Collapse, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const campaignListSkeletonStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& .eachItem": {
      padding: "20px 32px",
      border: "1px solid rgba(148, 157, 178, 0.24)",
      borderRadius: "8px",
      flex: "1 1 auto",
      transition: "all 0.3s linear",

      "& .title": {
        fontSize: "20px",
        fontWeight: 600,
      },

      "& .description": {
        display: "flex",
        alignItems: "center",
        gap: 16,
        color: "#546376",
      },

      "& .campaignType": {
        position: "relative",
        "&:after": {
          position: "absolute",
          content: '""',
          right: -8,
          top: 3,
          height: 15,
          width: 1,
          background: "rgba(148,157,178,0.24)",
        },
      },

      "& .campaignDate": {
        display: "flex",
        alignItems: "center",
        gap: "5px",
      },

      "& .status": {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        borderTop: "1px solid rgba(148, 157, 178, 0.24)",
        paddingTop: "16px",
        marginTop: "16px",
      },

      "& .statusItemWrapper": {
        display: "flex",
        alignItems: "center",
        gap: "8px",

        "& .statsTitle": {
          fontSize: "11px",
          color: "#546376",
        },
      },
    },
  },
}));

const each_campaign_list_stats_icon = [
  { index: 19, title: "Contacts", link: "/contacts?campaignId=" },
  {
    index: 20,
    title: "Contacted",
    link: '/contacts?contacted="contacted"&campaignId=',
  },
  {
    index: 21,
    title: "Responded",
    link: '/contacts?responded="responded"&campaignId=',
  },
  { index: 22, title: "Response Rate", link: "" },
  { index: 23, title: "Lead Flow", link: "/leadFlow?campaignid=" },
];

const CampaignListSkeleton = ({ isStatusCollapsed }) => {
  const classes = campaignListSkeletonStyles();

  const renderStats = () => {
    const view = [];
    each_campaign_list_stats_icon.forEach((statsItem, index) => {
      //will get this data from props
      view.push(
        <div
          className={"statusItemWrapper"}
          key={index}
          // onClick={() => goToLink(item.link)}
        >
          <div className="statsIcon">
            <Skeleton
              variant="rounded"
              height="28px"
              width="28px"
              style={{ borderRadius: '50%'}}
            />
          </div>
          <div
            className="statsContent"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span className="statsValue">
              <Skeleton variant="rect" height="17px" width="70px" style={{ marginBottom: "4px" }} />
            </span>
            <span className="statsTitle">
              <Skeleton variant="rect" height="17px" width="30px" />
            </span>
          </div>
        </div>
      );
    });
    return view;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {[...Array(6)].map((_, index) => (
        <div key={index} className={classes.root}>
          <Skeleton
            variant="rounded"
            height="28px"
            width="28px"
            style={{ marginInline: "12px" }}
          />

          <div className="eachItem">
            <span className="title">
              <span>
                <Skeleton
                  variant="rect"
                  height="17px"
                  width="500px"
                  style={{ marginBottom: "12px" }}
                />
              </span>
            </span>

            <div className="description">
              <div className="campaignType">
                <Skeleton variant="rect" height="17px" width="100px" />
              </div>

              <div className="campaignDate">
                <Skeleton variant="rect" height="17px" width="100px" />
              </div>
            </div>

            <Collapse in={!isStatusCollapsed}>
              <div className="status">{renderStats()}</div>
            </Collapse>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CampaignListSkeleton;

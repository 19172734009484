import { makeStyles } from "@material-ui/core";

export const GlobalAddDealModalStyle = makeStyles((theme) => ({
    modal: {
        display: "flex",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    dealNameContainer: {
    height: '20px',
    borderRadius: '16px',
    backgroundColor: 'white',
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    minWidth:'210px',
    
  },
  dealButton: {
    backgroundColor: "#445af3",
    borderRadius: "4px",
    cursor: "pointer",
    padding:"6px 10px",
    border: "1px solid #445af3",
    "&:hover": {
      backgroundColor: "#001ff6",
      border: "1px solid #001ff6",
    },
    "&:focus": {
      backgroundColor: "#445af3",
    },
  },
  cancelButton: {
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    cursor: "pointer",
    padding:"6px 25px",
    border: "1px solid #a4a4a4",
    "&:hover": {
      backgroundColor: "#f3f4f6",
    },
    "&:focus": {
      backgroundColor: "#ffffff",
    },
  },
  logoContainer: {
    width: "50px",
    height: "50px",
  },
  logo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

export const useStyles = makeStyles({
    titleText: {
        color: ' #181f48',
        fontWeight: "600 !important",
        marginBottom: "0px !important"
    },
    productValueSign: {
        position: "absolute",
        top: "8px",
        left: "10px",
        zIndex: 1
    },
    addDealButton: {
        minWidth: "140px",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#445af3",
        borderRadius: "4px",
        padding: "4px 15px",
        border: "none",
        gap: 5,
        cursor: "pointer",
        minHeight: "36px",
        "&:hover": {
          backgroundColor: "#001ff6",
        },
        "&:focus": {
          backgroundColor: "#445af3",
        },
      },
      buttonLabel: {
        color: "white",
        fontWeight: 500,
        textAlign: "center"
      },
})
import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  padding: 30px 24px;
  background-color: #f6f7fb;
  border-top: none;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: auto;
  z-index: 10;
  border-top: 2px solid #e5e8ef;
`;
 
export const StyledButton = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor:pointer;

  &.primary {
    background-color: #3e50f7;
    color: white;
    font-weight: bold;
    border: 1px solid #3e50f7;
    padding:8px 38px;

    &:hover:not(:disabled) {
      background-color: #2563eb;
      border-color: #2563eb;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  &.secondary {
    background-color: white;
    color: #000000;
    font-weight: bold;
    border: 1px solid #a4a4a4;
    padding:8px 30px;

    &:hover {
      background-color: #f3f4f6;
      border-color: #a4a4a4;
    }
  }
`;
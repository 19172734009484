import React from "react";

const EmptyStateMessageWithPicture = ({ message = "No quick reply created yet.", imgWidth = 300 }) => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <img
        width={imgWidth}
        src={
          `${process.env.REACT_APP_CDN_LINK}assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png`
        }
        alt="Empty data"
      />
      <p style={{ fontSize: "18px" }}>{message}</p>
    </div>
  );
};

export default EmptyStateMessageWithPicture;

import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Button, Slide } from "@material-ui/core";
import classNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import AddTagModal from "./AddTagModal";
import { customModalStyle } from "./customModalStyle";

const CustomModal = ({ open, onClose, title, children, className, buttonText, onSubmit, submitButtonDisable, onCreateTag, selectedPrivacyLevel }) => {
  const [isAddTagModalOpen, setIsAddTagModalOpen] = useState(false);
  const classes = customModalStyle();

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Slide in={open} direction='left'>
        <div className={classNames(classes.modalWrapper, className)}>
          <div className={classes.paper}>
            <div className={classes.header}>
              <div className={classes.headerText}>
                <img
                  src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
                  alt='tag img'
                  height={50}
                />
                <div style={{ fontSize: "20px", fontWeight: 600 }}>{title}</div>
              </div>
              <div>
                <button
                  className={classNames(classes.button, classes.confirmButton, 'addNewUserButton')}
                  onClick={() => setIsAddTagModalOpen(true)}
                >
                  <AddIcon /> <span>Add New Tag</span>
                </button>
                {isAddTagModalOpen && (
                  <AddTagModal
                    open={isAddTagModalOpen}
                    onClose={() => setIsAddTagModalOpen(false)}
                    onCreateTag={onCreateTag}
                    selectedPrivacyLevel={selectedPrivacyLevel}
                  />
                )}
              </div>
            </div>
            <div style={{ minHeight: `calc(100vh - 188px)` }}>{children}</div>
            <div className={classes.actions}>
              <button className={classNames(classes.button, classes.closeButton)} onClick={handleClose}>
                <div>Cancel</div>
              </button>
              {buttonText ? (
                  <button
                      onClick={() => {
                        onSubmit();
                      }}
                      disabled={submitButtonDisable}
                      className={classNames(classes.button, classes.confirmButton)}
                  >
                    <span>{buttonText}</span>
                  </button>
              ) : null}
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default CustomModal;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CoreConstants from "./CoreConstants";
import LinkFile from "./LinkFile";
import { getMessageTemplates } from "./redux/videoEmailAction";
import './saved_reply.css';
import { PRIVACY_LEVEL } from "../../../helpers/CoreConstant";
import GlobalPrivacyTab from "../globalPrivacyTab";
import EmptyStateMessageWithPicture from "../../GlobalContactDetailsForDeal/LeftSide/common/EmptyStateMessageWithPicture";

const SavedReplyForVideoTemplate = props => {
    const [messageTemplates, setMessageTemplates] = useState(props.messageTemplates);
    const [searchKey, setSearchKey] = useState('');
    const [loading, setLoading] = useState(true);

    const [activeTab, setActiveTab] = useState(PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL);

    useEffect(() => {
        if (props.messageTemplates === null) {
            props.getMessageTemplates({ search_key: '', type: props.templateType, privacy_level: activeTab });
        } else {
            setMessageTemplates(props.messageTemplates);
            setLoading(false);
        }
    }, [props.messageTemplates, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.getMessageTemplates({ search_key: searchKey, type: props.templateType, privacy_level: activeTab });
    }, [props.templateType, searchKey, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectThisMessageTemplate = (e, data) => {
        if(e !== null){
            e.preventDefault();
            e.stopPropagation();
        }

        props.useMessageTemplate(data);
    }

    const renderMessageTemplates = () => {
        let messageTemplateOptions = [];
        if (!loading) {
            if (messageTemplates !== null && messageTemplates.length) {
                messageTemplates.map((data, index) => {
                    messageTemplateOptions.push(
                        <LinkFile.QuickReplyCard 
                            data={data}
                            key={index}
                            onSelect={(data) => selectThisMessageTemplate(null, data)}
                        /> 
                    )
                    return;
                    // messageTemplateOptions.push(
                    //     <div className="col s6 m6 l4 xl4">
                    //         <div className="card manage_modal_card">
                    //             <div className="card-image">
                    //                 <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.EMAIL}>
                    //                     <p>Subject: { data.subject }</p>
                    //                     <p>Message: { data.message }</p>
                    //                 </If>
                    //                 <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.SMS}>
                    //                     <p>{ data.message }</p>
                    //                 </If>
                    //                 <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.VOICE_MAIL}>
                    //                     <audio controls="controls" src={data.video_file_url} />
                    //                 </If>
                    //                 <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}>
                    //                     <LazyLoadImage
                    //                         onError={(e) => e.target.src = `${process.env.REACT_APP_APP_URL}/assets/images/notFound.png`}
                    //                         alt={data.gif_file_url}
                    //                         effect="blur"
                    //                         src={data.gif_file_url} />
                    //                 </If>
                    //                 <If condition={props.templateType == CoreConstants.MESSAGE_TEMPLATE.MMS}>
                    //                     <p>{ data.message }</p>
                    //                     <img src={data.video_file_url} alt={data.video_file_url}/>
                    //                 </If>
                    //                 <If condition={(props.templateType == CoreConstants.MESSAGE_TEMPLATE.POST_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.GREETING_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.GIFT_CARD || props.templateType == CoreConstants.MESSAGE_TEMPLATE.LETTER)}>
                    //                     <p>{ data.message }</p>
                    //                     <img src={data.gif_file_url} alt={data.gif_file_url}/>
                    //                 </If>
                    //             </div>
                    //             <a href="#" onClick={(e) => selectThisMessageTemplate(e, data)}>Use this Template</a>
                    //             <p className="template-type">{ templateType(data.type) }</p>
                    //             <p className="template-title">
                    //                 <span className="truncate">{ data.title }</span>
                    //             </p>
                    //         </div>
                    //     </div>
                    // );
                });
            } else {
                return <EmptyStateMessageWithPicture message="No saved reply found related to video email in this system!" />
            }
        } else {
            for (let i = 0; i < 6; i++) {
                messageTemplateOptions.push(
                    <div className="col s6 m6 l4 xl4">
                        <div className="card manage_modal_card">
                            <div className="card-image">
                                <LinkFile.skeleton width='100%'/>
                                <LinkFile.skeleton  width='100%' height={87}/>
                                <LinkFile.skeleton  width='100%' height={70}/>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return messageTemplateOptions;
    }

    const handleActiveTab = (_event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <LinkFile.globalModal 
            open={props.openSavedReplyModal} 
            onClose={() => props.setOpenSavedReplyModal(false)} 
            title={"Saved Reply"}
            className={'saved_reply_modal_wrapper'}
            hideFooter={true}
        >
            <div className="video_library_wrapper">
                <div className="video_thumbnails modal_thumbnail">
                    <GlobalPrivacyTab activeTab={activeTab} handleTabChange={handleActiveTab} />
                    <div className="row video_thumbnails_scroll awesome__scroll_bar">
                        { renderMessageTemplates() }
                    </div>
                </div>
            </div>
        </LinkFile.globalModal>
    );
}

const mapStateToProps = state => {
    return {
        messageTemplates: state.videoEmailReducer.messageTemplates,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMessageTemplates: (params) => dispatch(getMessageTemplates(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedReplyForVideoTemplate);
import React, { useEffect, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  styled
} from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import { Skeleton } from "@material-ui/lab";
import EachFolderItem from "./EachFolderItem";
import { SidebarLeftIcon, SidebarRightIcon } from "../Icons";
import { CustomScrollbar } from "../assignToCampaignStyles";
import { getCampaignFoldersApi } from "../helper/Api";

const SidebarWrapper = styled("div")(({ theme, isSidebarCollapsed }) => ({
  minWidth: isSidebarCollapsed ? 56 : 268,
  height: "calc(100vh - 180px)",
  transition: " 350ms ease",
  background: "white",
  overflow: "hidden",
  padding: "16px 10px",
  position: "relative",
  borderRadius: theme.spacing(1),

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },

  "& .sidebarHeader": {
    display: "flex",
    justifyContent: isSidebarCollapsed ? "center" : "space-between",
    alignItems: "center",
    padding: isSidebarCollapsed ? "0 !important" : "0 8px !important",
  },

  "& .sidebarTitle": {
    display: isSidebarCollapsed ? "none" : "block",
    fontWeight: 600,
    minWidth: 170,
    color: theme.palette.text.secondary,
  },

  "& .sidebarList": {
    "& .Mui-selected": {
      background: "rgba(52, 111, 239, 0.08)",
      borderRadius: 8,
      "& .MuiTypography-root": {
        fontWeight: 600,
      },
    },
    "& .MuiListItem-button:hover": {
      borderRadius: theme.spacing(1),
    },
    "& .MuiListItem-gutters": {
      padding: isSidebarCollapsed ? "8px 0" : "4px 8px",
    },
    "& .MuiListItemIcon-root": {
      minWidth: isSidebarCollapsed ? 35 : 30,
      justifyContent: isSidebarCollapsed ? "center" : "flex-start",
    },
    "& .MuiListItemText-root": {
      minWidth: 170,
      display: isSidebarCollapsed ? "none" : "block",
    },
    "& .MuiListItemSecondaryAction-root": {
      display: isSidebarCollapsed ? "none" : "block",
      right: 8,
    },

    "& .folderList": {
      display: "flex",
      flexDirection: "column",
      gap: 4,

      "& svg": {
        fontSize: 20,
      },
    },
  },

  "& .sidebarDivider": {
    margin: "12px",
  },
}));

const SideBar = ({
  selectedFolder,
  handleSelectedFolder,
}) => {
  const [folderList, setFolderList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(()=> {
    const getFolderList = async () => {
      setIsLoading(true);
      
      try {
        const response = await getCampaignFoldersApi();

        if(response.data.status === 'success') {
          setFolderList(response.data.data);
        }else{
          setFolderList([]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    getFolderList();
  },[])

  const handleCollapsed = () => {
    setIsSidebarCollapsed((prevState) => !prevState);
  };

  const renderFolderList = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
          <Skeleton height={45} />
        </>
      );
    } else if (folderList?.[0] && !isLoading) {
      return folderList.map((folder) => (
        <EachFolderItem
          key={folder.id}
          folder={folder}
          selectedFolder={selectedFolder}
          onSelectFolder={(id) => handleSelectedFolder(id)}
        />
      ));
    }
  };

  const handleShowAllCompanies = () => {
    handleSelectedFolder(null);
  };

  return (
    <SidebarWrapper isSidebarCollapsed={isSidebarCollapsed}>
      <div className="sidebarHeader">
        <Typography className="sidebarTitle" variant="body2">My Campaigns</Typography>

        <IconButton color="primary" onClick={handleCollapsed} size="small">
          {isSidebarCollapsed ? <SidebarRightIcon /> : <SidebarLeftIcon />}
        </IconButton>
      </div>

      <List
        className="sidebarList"
        component="div"
        aria-label="main mailbox folders"
        dense
      >
       <ListItem
          button
          selected={selectedFolder === null}
          onClick={handleShowAllCompanies}
          style={{ marginBottom: 4 }}
        >
          <ListItemIcon>
            <FolderIcon
              color="primary"
              fontSize="small"
              style={{ color: "rgb(84, 99, 118)" }}
            />
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={"text.primary"}
              variant="body2"
              fontWeight={600}
              minWidth={170}
            >
              All Campaigns
            </Typography>
          </ListItemText>
        </ListItem>

        <CustomScrollbar style={{ maxHeight: "calc(100vh - 320px)" }}>
          <div className="folderList">{renderFolderList()}</div>
        </CustomScrollbar>
      </List>
    </SidebarWrapper>
  );
};

export default SideBar;

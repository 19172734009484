import React, { useEffect, useState } from "react";
import { Drawer } from "@material-ui/core";
import { PRIVACY_LEVEL } from "../../../helpers/CoreConstant";
import CampaignBody from "./campaignBody";
import DrawerFooter from "./DrawerFooter";
import DrawerHeader from "./DrawerHeader";
import SideBar from "./sidebar/SideBar";
import { addContactsInCampaign, getCampaignListApi } from "./helper/Api";
import SubmitModal from "./SubmitModal";

const AssignToCampaign = ({ open, onClose, data }) => {
  const [campaignList, setCampaignList] = useState(null);
  const [totalCampaignCount, setTotalCampaignCount] = useState(0);

  const [isCampaignLoading, setIsCampaignLoading] = useState(false);

  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [activeTab, setActiveTab] = useState(
    PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL
  );

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [filters, setFilters] = useState({
    search: null,
    status: null,
    createdBy: null,
  });

  const [isProcessModal, setIsProcessModal] = useState(false);

  useEffect(() => {
    const getCampaignList = async () => {
      setIsCampaignLoading(true);

      try {
        const payload = {
          privacyLevel: activeTab,
          status: filters.status,
          folderId: selectedFolder,
          createdBy: activeTab === PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE ? null : filters.createdBy,
          search: filters.search,
          page: page + 1,
          perPage: perPage,
        };

        const response = await getCampaignListApi(payload);

        if (response.success) {
          setCampaignList(response?.data);

          if (response?.total_count) {
            setTotalCampaignCount(response?.total_count);
          }
        } else {
          setCampaignList([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCampaignLoading(false);
      }
    };

    getCampaignList();
  }, [activeTab, filters, selectedFolder, page, perPage]);

  const resetAllState = () => {
    setSelectedCampaign(null);
    setFilters({
      search: null,
      status: null,
      createdBy: null,
    })
    setPage(0)
    setPerPage(10)
  }

  const handleSelectedFolder = (folderId) => {
    setSelectedFolder(folderId);
    resetAllState();
  };

  const handleTabChange = (_event, value) => {
    setActiveTab(value);
    resetAllState();
  };

  const handleClose = () => {
    onClose();
    if(data?.onClose){
      data.onClose();
    }
  };

  let tabId = Math.floor(Math.random() * 100 + 1);
  let payload = {
    campaignId: selectedCampaign?.id,
    contact_ids: data?.selectedContactIds,
    tabId: tabId,
  };

  const onSubmit = async () => {
    try {
      if(data?.from === "contacts"){
        setIsProcessModal(true);
      }
      else{
        const response = await addContactsInCampaign(payload);

        if (response.data.status === "success") {
          window.showNotification(
            "SUCCESS",
            response.data.html === "Success"
              ? "Contacts Move to Campaign Successfully"
              : response.data.html
          );
  
          if(data?.callBack){
            data.callBack()
          }
  
          handleClose();
        } else {
          window.showNotification("ERROR", "Something went wrong");
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div
        role="presentation"
        style={{ backgroundColor: "#F5F7F9", width: "1140px", height: "100%" }}
      >
        <DrawerHeader />

        <div
          style={{
            fontFamily: "Poppins, sans-serif",
            padding: "16px",
            display: "flex",
            gap: "16px",
          }}
        >
          <SideBar
            selectedFolder={selectedFolder}
            handleSelectedFolder={handleSelectedFolder}
          />

          <CampaignBody
            campaignList={campaignList}
            isCampaignLoading={isCampaignLoading}
            totalCampaignCount={totalCampaignCount}
            selectedCampaign={selectedCampaign}
            setSelectedCampaign={setSelectedCampaign}
            filters={filters}
            setFilters={setFilters}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
          />
        </div>

        <DrawerFooter
          onClose={handleClose}
          onSubmit={onSubmit}
          selectedCampaign={selectedCampaign}
        />

        <SubmitModal
          drawerPayload={payload}
          open={isProcessModal}
          onClose={() => setIsProcessModal(false)}
          onCloseDrawer={handleClose}
          campaignName={selectedCampaign?.title}
          callBack={data?.callBack || (() => {})}
        />
      </div>
    </Drawer>
  );
};

export default AssignToCampaign;

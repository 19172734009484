import React, { useState } from "react";
import {
  Button,
  Dialog,
  Grid,
  Typography
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import { TagStyleForAddorEditModal } from "./TagStyleForAddorEditModal";
import BasicInput from "../../../../custom/BasicInput";
import BasicSelect from "../../../../custom/BasicSelect";
import {PRIVACY_LEVEL} from "../../../../../helpers/CoreConstant";
import {addTagWithPermissionLevel} from "../../../../../api/contactApi";

const colors = [
  "#3E50F7", "#E6EBFF", "#25224A", "#000000", "#66FCF1",
  "#FF0055", "#FFC905", "#33F28B", "#595959", "#3AAFA9", 
  "#FC4A1A", "#DFDCE3",
];

const AddTagModal = ({ open, onClose, onCreateTag, selectedPrivacyLevel }) => {
  const [selectedColor, setSelectedColor] = useState("#3E50F7");
  const classes = TagStyleForAddorEditModal();
  const [tagName, setTagName] = useState('');
  const [tagPrivacyLevel, setTagPrivacyLevel] = useState(selectedPrivacyLevel);
  const [errorMessage, setErrorMessage] = useState({
    tagName: '',
    tagPrivacyLevel: '',
  });

  const validateForm = () => {
    const errors = {};

    if (!tagName.trim()) {
      errors.tagName = "Tag name is required.";
    }

    if (!tagPrivacyLevel.trim()) {
      errors.tagPrivacyLevel = "Privacy level is required.";
    }

    setErrorMessage(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const newTag = {
      title: tagName,
      privacyLevel: tagPrivacyLevel,
      color: selectedColor,
    };

    const response = await addTagWithPermissionLevel(newTag);

    if (response?.success && response.data){
      onCreateTag(response.data);
      window.showNotification("SUCCESS", "Tag added successfully");
      onClose();
    }else {
      window.showNotification('ERROR', response.message || 'Something went wrong!');
    }
  };

  const PRIVACY_OPTION = [
    { label: "My Tags", value: PRIVACY_LEVEL.PRIVACY_LEVEL_PRIVATE },
    { label: "Global Tags", value: PRIVACY_LEVEL.PRIVACY_LEVEL_GLOBAL },
  ];

  return (
    <Dialog
      minWidth={500}
      open={open}
      onClose={onClose}
      className={classes.modalWrapper}
    >
      <div className="modalHeader">
        <div className="headerLeft">
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/tags/1.0.0/tags_icon.png`}
            alt=""
            height={50}
          />
          <div className="headerText">
            <h5 className="title">Create a New Tag</h5>
          </div>
        </div>
      </div>

      <div className="modalBody">
        <div className={classes.contentWrapper}>
          <div className={classes.section}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <div className={classes.fieldLabel}>
                  Tag Name <span style={{ color: "var(--red)" }}>*</span>
                </div>
                <BasicInput
                  name="fullName"
                  value={tagName}
                  placeholder="Enter Tag Name"
                  onChange={(e) => {
                    setTagName(e.target.value);
                    setErrorMessage((prevState) => ({
                      ...prevState,
                      tagName: "",
                    }));
                  }}
                  fullWidth
                  required
                />
                {errorMessage.tagName && (
                  <div className={classes.errorText}>{errorMessage.tagName}</div>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.fieldLabel}>
                  Type <span style={{ color: "var(--red)" }}>*</span>
                </div>
                <BasicSelect
                  fullWidth
                  name="category"
                  defaultText="Select Tag Type"
                  value={tagPrivacyLevel}
                  options={PRIVACY_OPTION}
                  mapping={{ label: "label", value: "value" }}
                  onChange={(e) => {
                    setTagPrivacyLevel(e.target.value);
                    setErrorMessage((prevState) => ({
                      ...prevState,
                      tagPrivacyLevel: "",
                    }));
                  }}
                />
                {errorMessage.tagPrivacyLevel && (
                  <div className={classes.errorText}>{errorMessage.tagPrivacyLevel}</div>
                )}
              </Grid>

              <Grid item xs={12}>
                <div style={{ marginBottom: "20px" }}>
                  <Typography>Choose a tag color</Typography>
                  <div className={classes.colorMainContainer}>
                    {colors.map((color) => (
                      <div
                        key={color}
                        className={classes.outerIconColor}
                        style={{ background: color }}
                        onClick={() => setSelectedColor(color)}
                      >
                        {selectedColor === color && (
                          <div
                            style={{ background: color }}
                            className={classes.innerIconColor}
                          >
                            <CheckIcon
                              className={classes.checkIconColor}
                              fontWeight={600}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <p style={{ textAlign: "center", margin: "0px" }}>
                  Admins with the proper permissions can create both Global
                  Tags and My Tags.<br /> Global Tags are shared with all users on
                  your team. My Tags are individual<br /> tags only available in
                  your account.
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

      <div className="modalFooter">
        <Button
          variant="outlined"
          className="cancelButton"
          color="secondary"
          onClick={onClose}
          size="small"
          disableElevation
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          className="submitButton"
          color="primary"
          size="small"
          disableElevation
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default AddTagModal;
